//@CHARSET "ISO-8859-1";

/* ECOSYS Popup css and Overrides of dhtmlxwindows_dhx_skyblue.css */

@use '../../styles/scss/_globals.scss';

/* DHX Window Skyblue theme overrides */

.dhxwins_vp_dhx_skyblue {
	div.dhxwin_active, div.dhxwin_inactive {
		border: 1px solid grey;
		box-shadow: 0px 28px 80px -6px rgba(0,0,0,0.4);
		background: globals.$white;
		border-radius: 6px;
		overflow: visible;
		margin-top: 0;
		&.dhxwin_dnd {
			box-shadow: 0px 28px 80px -6px rgba(0,0,0,0.4);
		}
		&.minmaxed {
			margin: 1px;
		}

		/** EMPTY FRAME SKIN ***/
		&.emptyFrame {
			top: 0;
			left: 0;
			box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.4);
			div.dhxwin_brd.dhxwin_hdr_hidden, div.dhxwin_hdr.dhxwin_hdr_hidden {
				visibility: hidden;
			}
			div.dhx_cell_wins {
				top: 4px !important;
				background: transparent;
				div.dhx_cell_cont_wins {
					background: transparent;
				}
			}

		}
	}

	div.dhxwin_resize {
		border-radius: 6px;
	}

	div.dhxwin_brd {
		border-color: transparent;
		border-style: solid;
		border-width: 0px 4px 4px 4px;
		background: transparent;
		z-index: -1;

		&.dhxwin_hdr_hidden {
			border-top-width: 2px;
			border-color: transparent;
		}

	}
	div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon {
		top: 4px;
	}

	div.dhxwin_active div.dhx_cell_wins,
	div.dhxwin_inactive div.dhx_cell_wins {
		border-radius: 0 0 5px 5px;
	}
	div.dhxwin_active div.dhx_cell_wins div.dhx_cell_cont_wins,
	div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_cont_wins {
		border: 0px solid #3A76BC;

		.dhxlayout_base_dhx_skyblue {
			border-radius: 0 0 5px 5px;
		}
	}

	div.dhxwin_hdr {
		margin-bottom: 0;
		height: 29px;
		line-height: 29px;
		background-image: none;
		filter: none;
		background-color: globals.$dark-blue;
		color: globals.$white;
		border: none;
		border-radius: 5px 5px 0 0;


		div.dhxwin_icon {
			display: none; /* this icon in the upper left corner is useless */
			top: 6px;
		}

		div.dhxwin_btns {
			display: flex;
			gap: 1px;
			height: 100%;
			top: 0px;
			right: 0px;
			align-items: center;

			> div {
				display: flex;
				justify-content: center;
				width: 28px;
				height: 100%;
				align-items: center;

				&:hover {
					background-color: globals.$another-light-grey;
				}
				.svg-icon {
					display: flex;
					width: 60%;
					fill: black;
				}
			}
		}


		/* options button, enabled states */
		div.dhxwin_btns div.dhxwin_button.dhxwin_button_options {
			background-image: url(button_options.gif);
			background-position: 0px 0px;
		}
		/* conf, disabled state */
		div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_options_dis {
			background-image: url(button_options.gif);
			background-position: 0px -15px;
		}
		/* conf, pressed state */
		div.dhxwin_btns div.dhxwin_button.dhxwin_button_options_pressed {
			background-image: url(button_options.gif);
			background-position: 0px 0px;
			background-color: #a4bed4!important;
		}
		div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_options_pressed_dis {
			background-image: url(button_options.gif);
			background-position: 0px -15px;
			background-color: #f0f0f0!important;
		}

	}
}

/***  Buttons ****/

.popupButtonPane {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: globals.$white;
	border-top: 1px solid #D5D5D5;
	height: 100%;
	line-height: 15px;

	.popupButton {
		height: 22px;
	}
}

.popupButton {
	margin: 3px 5px 2px 5px;
	min-width: 80px;
	max-width: 130px; /* for now, could get smaller if needed */
	position: relative;
}

.multiListButton {
	width: 26px;
	height: 28px;
	border: 1px solid #868686;
	background-color: #fff;
	margin: 0px 0px 4px 0px;
	padding: 0px;
	cursor: pointer;
	vertical-align: top;
}

/** Color picker popup **/

#colorPickerDiv {
	table.jPicker {
		background-color: transparent;
		border: none;

		.Map, .Bar, .Preview div {
			border: 1px solid var(--container-border-color);
		}

		.Preview div {
			height: 61px;
			width: 61px;
		}
		.Grid {
			width: 100%;
		}
	}

}
