

div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected {
    display: inline-flex; /* This makes the down arrow icon stay inline with the div (also makes icon appear on left side) */
	align-items: center;
	margin: 0; /* push the dropdown up */
	padding: 2px 4px;
}

div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal i,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled i,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected i {
	margin: 0;
	height: auto;
}

.simpleDropdownControlContainer a{
	text-decoration: none;
	color: #000;
	cursor: pointer;
	padding: 1px 3px 1px 7px;
}

.simpleDropdownControlContainer .noLabel a{
	padding: 1px 3px 1px 4px;
}

.dropdownControlContainer{
	/* border: 1px solid #A9AAA9; */
	padding: 0;
	margin: 0 1px 0 0;
	height: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.dropdownControlContainer div div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal,
.dropdownControlContainer div div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled,
.dropdownControlContainer div div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected {
	cursor: pointer !important;
	width: 80px\9;
	/* display: inline-block; */
}

.dropdownControlContainer div img.dhtmlxMenu_TopLevel_Item_Icon {
	margin: 5px 10px 0px 1px;
	float: right;
}

.cornerMenu div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal,
.cornerMenu div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled,
.cornerMenu div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected {
	padding-left: 0px;
	padding-right: 0px;
	color: grey;
}

.cornerMenu img.dhtmlxMenu_TopLevel_Item_Icon {
	float: left;
	margin: 0px 0px 0px 1px !important;
	width: 15px;
	height: 15px;
}

/* Overwrite */
/* should change the epc-screen.css instead later on */