/*
Overrides of dhtmlxCombo/codebase/skins/dhtmlxcombo_dhx_skyblue.css
*/

div.dhxcombo_dhx_skyblue{
	height: 21px;
	text-align: left;
	border: 1px solid #868686;
	border-radius: 2px;
	outline: none;
	padding-bottom:1px;
}
div.dhxcombo_dhx_skyblue input.dhxcombo_input {
	top: 2px;
	height: 17px;
	font-size: 13px;
	line-height: 15px;
	padding-left: 2px;
}

.dhx_combo_input{
	font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
	font-size: 14px;
}

.dhx_combo_box{
	height:20px;
}

.dhx_combo_list{
   font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
   font-size: 14px;
}

.dhx_combo_list{
	z-index: 300; /* so it says on top of our disabled message  */
}

