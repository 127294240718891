


DIV.listControlDiv.gridbox_eco DIV.objbox TABLE.obj TD
{
	border: none;
	font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
	font-size: 11px;
}

DIV.listControlDiv.gridbox_eco DIV.objbox TABLE.row20px TR TD {
	padding: 1px 3px;
}

.listControlDiv.gridbox_eco {
	border: 1px solid #7f9db9;
}

DIV.listControlDiv.gridbox_eco DIV.objbox table.obj tr.rowselected td {
	background: #3399ff; 
	border: none;
	color: white;
	clear: both;
}

DIV.listControlDiv.gridbox_eco DIV.fontIcoDiv {
	display: inline;
	width: 30px;
	height: 20px;
	font-size: 14px;
	text-align: center;
	line-height: 22px;
	padding-top: 2px;
	margin-right: 4px;
}

DIV.listControlDiv.gridbox_eco DIV.fontIcoDiv.hidden {
	visibility: hidden;
}

