
@use "../../styles/scss/_globals";

@use "../combo/combo.scss";


.comboContainerTable{
	display: inline-table;
	border: 0;
	padding: 0;
	margin: 0;
	white-space: nowrap;

	&.link {
		a.disabled {
			color: globals.$disable-grey;
			cursor: not-allowed;
		}
	}
}

.comboContainerTable TD{
	border: 0;
	padding: 0;
	margin: 0;
	vertical-align: top;
	text-align: left;
	white-space: nowrap;
}

.linkModeValueMouseOver{
	border-bottom: 1px solid #7F9DB9 !important;
}

.linkModeValueMouseOut {
	border-bottom: none;
}
.link .comboLookupButtonTD {
	flex-direction: row-reverse;
	align-items: center;
	padding: 0;
}
.comboLookupButton {
	width: 18px;
	height: 20px;
	text-align: center;
	vertical-align: middle;
	padding: 0;
	position: relative;
	border: thin solid globals.$not-so-light-grey;
	border-radius: 2px;
}


.link .comboLookupButton {
	font-size: 11px;
	height: 18px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 2px;
	color: var(--toolbar-icon-color);
	background: var(--hex-light-grey);
}

.link .comboLookupButton.lower i {
	padding: 4px 0px 0px 0px;
}

.link .comboLookupButton {
	&:hover {
		background: var(--toolbar-icon-hover-bg);
	}
	&:disabled {
		background: globals.$disable-grey;
		cursor: not-allowed;
	}
}

.comboLookupButton IMG{
	width: 15px;
	height: 15px;
	margin: 0;
}
.comboLookupButton DIV{
	width: 15px;
	height: 15px;
	margin: 2px 0 0;
	cursor: default;
}

.comboLookupButton .delIcon {
	color: #fc381b;
}



.comboLookupLink {
	visibility: hidden;
}

.comboLookupLinkHover {
	visibility: visible;
}
