/* ECOSYS Overrides of dhtmlxlayout_dhx_sky.css */

@use '../../styles/scss/_globals.scss';

/*  Override of dhtmlx's style, which seems to be for full-screen mode. Should be a better way so this doesn't need to be hear. */
body.dhxwins_vp_auto{
    overflow: hidden !important;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow {
	top: 0;
	background-image: url("dhxlayout_btns_ecosys.gif");
	background-position: -32px 0;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_hb{
	top: 0;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_hb {
	top: 4px;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_ha {
	background-position: 0 -1px;	
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr{
	height: 17px;
	font-size: 10 !important;
	line-height: 16px !important;
	color: #3a75bb;
	border: 1px solid #a4bed4;
	border-right: 0;
	border-left: 0;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_cont_layout {
	border-left: 0 solid #a4bed4;
	border-right: 0 solid #a4bed4;
	border-bottom: 0 solid #a4bed4;
	border-top: 0 solid #a4bed4;
}

BODY.touch .dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_cont_layout {
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr.dhx_cell_hdr_hidden,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr.dhx_cell_hdr_hidden,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr.dhx_cell_hdr_hidden{
	height: 0;
	line-height: 0 !important;
	border: none !important;
	overflow: hidden;
}
#parentLayout .dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr.dhx_cell_hdr_hidden{
	line-height: 28px !important; /*special case bc this is hidden header that shows when collapsed */
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr.dhx_cell_hdr_hidden_no_borders {
	height: 0  !important;
	line-height: 0  !important;
	border-width: 0  !important;
	overflow: hidden;
}


/* Maximize button on titlebar of dhtmlxLayout --- next 3 sections */ 

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_maxicon {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 0;
	right: 18px;
	text-align: center;
	vertical-align: top;
	cursor: pointer;
	background-image: url("dhxlayout_btns_ecosys.gif");
	background-position: -64px 0; 
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_maxicon_ha {

}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_maxicon.dhxlayout_maxicon_va,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_maxicon.dhxlayout_maxicon_vb {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 16px;
	right: 2px;
	text-align: center;
	vertical-align: top;
	cursor: pointer;
	background-image: url("dhxlayout_btns_ecosys.gif");
	background-position: -64px 0;
}



.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr {
	display: flex;
	align-items: center;
	background: globals.$chromebar none;
	filter: none;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr {
	border-right: 1px solid var(--container-border-color);
	border-left: 1px solid var(--container-border-color);
	border-bottom: none;
	border-top: none;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr {
	height: 34px;
	line-height: 22px;
	border-right: none;
	border-left: none;
	border-top: 1px solid var(--container-border-color);
	border-bottom: 1px solid var(--container-border-color);
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhx_cell_hdr_text {
	margin-top: 0;
	margin-left: 20px;
	color: globals.$dark-grey;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text {
	margin-left: 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr div.dhx_cell_hdr_text {
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: globals.$dark-blue;
	}
}


/* FOR DHTMLX LAYOUT to make all splitters 1px wide but allow for 6px of grabbing area */
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep.dhxlayout_sep_resize_v,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep.dhxlayout_sep_resize_h {
	background-color: var(--splitter-bar-bg);
	background-image: none;
}
/* trick to make a vert line in the middle of the 6px wide transparent sep */
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep.dhxlayout_sep_resize_v:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	border-left: 1px solid var(--splitter-bar-line);
	transform: translate(-50%);
}

/* trick to make a horiz line in the middle of the 6px tall transparent sep */
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep.dhxlayout_sep_resize_h:after {
	content: "";
	width: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	border-bottom: 1px solid var(--splitter-bar-line);
	transform: translate(0, -50%);
}



div.dhxlayout_sep_sw_dhx_skyblue {
	width: 1px !important; /* default for all seps */
}


.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr {
	div.dhxlayout_arrow.dhxlayout_arrow_ha, div.dhxlayout_arrow.dhxlayout_arrow_hb {
		top: 7px;
	}
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr {
	div.dhxlayout_arrow.dhxlayout_arrow_ha, div.dhxlayout_arrow.dhxlayout_arrow_hb {
		top: 7px;
	}
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr {
	height: 32px;
	font-size: 14px !important;
	font-weight: 500;
	letter-spacing: -.2px;
	line-height: 28px !important;
	background-color: globals.$chromebar;
	color: globals.$white;
	border-right: 0;
	border-left: 0;
	border-bottom: 1px solid var(--container-border-color);
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr {
	box-sizing: border-box;
	height: 34px;
	div.dhx_cell_hdr_text {
		font-weight: 500;
	}
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr {
	box-sizing: border-box;
	width: 29px;
	div.dhx_cell_hdr_text {
		font-weight: 500;
	}
}

/* DHTMLXLayout buttons modification > using EPC derivative from font-AWESOME! */
/* Maximize buttons */

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr {
	div.dhxlayout_maxicon {
		visibility: hidden;
		position: absolute;
		width: 20px;
		height: 20px;
		top: 7px;
		right: 21px;
		text-align: center;
		vertical-align: top;
		cursor: pointer;
		background: none;
		font-family: globals.$epcfonts !important;
		line-height: normal;
		font-size: 11px;
		color: globals.$grey;
		font-weight: 100;
	}
	div.dhxlayout_maxicon:after {
		content: "\ea26";
	}
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr {
	div.dhxlayout_maxicon.dhxlayout_maxicon_va, div.dhxlayout_maxicon.dhxlayout_maxicon_vb {
		position: absolute;
		width: 20px;
		height: 20px;
		top: 26px;
		right: 3px;
		text-align: center;
		vertical-align: top;
		cursor: pointer;
		background: none;
	}
	div.dhx_cell_hdr_text_chrome {
		margin-top: 32px;
	}
}

/* Max button END */

/* Resize buttons */

.dhxlayout_base_dhx_skyblue div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow {
	visibility: hidden;
	position: absolute;
	right: 4px;
	top: 7px;
	width: 20px;
	height: 20px;
	text-align: center;
	vertical-align: top;
	cursor: pointer;
	background: none;
	font-family: globals.$epcfonts !important;
	line-height: normal;
	font-size: 11px;
	font-weight: 100;
	color: globals.$grey;
}

.dhxlayout_base_dhx_skyblue div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_va {
	margin: 0;
	right: 7px;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_maxicon,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_maxicon.dhxlayout_maxicon_va,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_maxicon.dhxlayout_maxicon_vb {
	background: none !important;
	line-height: 16px;
}

.dhxlayout_base_dhx_skyblue div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_va:after,
.dhxlayout_base_dhx_skyblue div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_vb:after {
	content: "\ebf7"; /* chevron left */
}

.dhxlayout_base_dhx_skyblue div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_vb:after,
.dhxlayout_base_dhx_skyblue div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_va:after {
	content: "\ebf8"; /* chevron right */
}

.dhxlayout_base_dhx_skyblue div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_ha:after,
.dhxlayout_base_dhx_skyblue div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_hb:after {
	content: "\ebf9"; /* chevron up */
}

.dhxlayout_base_dhx_skyblue div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_hb:after,
.dhxlayout_base_dhx_skyblue div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_ha:after {
	content: "\ebf6"; /* chevron down */
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_vb {
	top: 7px;
	right: 7px;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_va,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_vb {
	top: 7px;
}

.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_cont_layout {
	border: none;
}

/* Move title START */

.dhxlayout_base_dhx_skyblue {
	.dhxlayout_cont {
		div.dhx_cell_layout.dhxlayout_collapsed_v {
			div.dhx_cell_hdr {
				div.dhx_cell_hdr_text {
					position: absolute;
					left: 5px;
					transform: rotate(90deg);
					transform-origin: left center;
					color: #333333;
					height: 15px;
					&.dhx_cell_hdr_text_chrome {
						left: 13px;
						text-align: right;
						height: 29px;
						span {
							margin-right: 24px;
						}
					}
				}
			}
		}
	}
}

/* Move title END */