.jPicker .Icon {
  display: inline-block;
  height: 24px; /* change this value if using a different sized color picker icon */
  position: relative; /* make this element an absolute positioning container */
  text-align: left; /* make the zero width children position to the left of container */
  width: 25px; /* change this value if using a different sized color picker icon */
}
.jPicker .Icon span.Color, .jPicker .Icon span.Alpha {
  background-position: 2px 2px;
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.jPicker .Icon span.Image {
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.jPicker.Container {
  color: #000;
  z-index: 10;
}
table.jPicker {
  background-color: #efefef;
  border: 1px outset #666;
  font-family: Arial, Helvetica, Sans-Serif;
  font-size: 12px !important;
  margin: 0px;
  padding: 5px;
  width: 545px;
  z-index: 20;
}
.jPicker .Move {
  background-color: #dddddd;
  border-color: #fff #666 #666 #fff;
  border-style: solid;
  border-width: 1px;
  cursor: move;
  height: 12px;
  padding: 0px;
}
.jPicker .Title {
  font-size: 11px !important;
  font-weight: bold;
  margin: -2px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
  text-align: center;
  width: 100%;
}
.jPicker div.Map {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #9a9a9a;
  border-right: 2px solid #fff;
  border-top: 2px solid #9a9a9a;
  cursor: crosshair;
  height: 260px; /* IE 6 incorrectly draws border inside the width and height instead of outside - We will fix this to 256px later */
  margin: 0px 10px 10px 10px;
  overflow: hidden; /* hide the overdraw of the Color Map icon when at edge of viewing box */
  padding: 0px;
  position: relative; /* make this element an absolute positioning container */
  width: 260px; /* IE 6 incorrectly draws border inside the width and height instead of outside - We will fix this to 256px later */
}
.jPicker div[class="Map"] {
  height: 256px; /* correct to 256px for browsers that support the "[class="xxx"]" selector (IE7+,Firefox,Safari,Chrome,Opera,etc.) */
  width: 256px; /* correct to 256px for browsers that support the "[class="xxx"]" selector (IE7+,Firefox,Safari,Chrome,Opera,etc.) */
}
.jPicker div.Bar {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #9a9a9a;
  border-right: 2px solid #fff;
  border-top: 2px solid #9a9a9a;
  cursor: n-resize;
  height: 260px; /* IE 6 incorrectly draws border inside the width and height instead of outside - We will fix this to 256px later */
  margin: 12px 10px 0px 5px;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 24px; /* IE 6 incorrectly draws border inside the width and height instead of outside - We will fix this to 20px later */
}
.jPicker div[class="Bar"] {
  height: 256px; /* correct to 256px for browsers that support the "[class="xxx"]" selector (IE7+,Firefox,Safari,Chrome,Opera,etc.) */
  width: 20px; /* correct to 20px for browsers that support the "[class="xxx"]" selector (IE7+,Firefox,Safari,Chrome,Opera,etc.) */
}
.jPicker .Map .Map1, .jPicker .Map .Map2, .jPicker .Map .Map3, .jPicker .Bar .Map1, .jPicker .Bar .Map2, .jPicker .Bar .Map3, .jPicker .Bar .Map4, .jPicker .Bar .Map5, .jPicker .Bar .Map6 {
  background-color: transparent;
  background-image: none;
  display: block;
  left: 0px;
  position: absolute;
  top: 0px;
}
.jPicker .Map .Map1, .jPicker .Map .Map2, .jPicker .Map .Map3 {
  height: 2596px;
  width: 256px; /* must specify pixel width. IE7/8 Quirks mode ignores opacity for an absolutely positioned item in a relative container with "overflow: visible". The marker in the colorBar
                   would not be drawn if its overflow is set to hidden. */
}
.jPicker .Bar .Map1, .jPicker .Bar .Map2, .jPicker .Bar .Map3, .jPicker .Bar .Map4 {
  height: 3896px;
  width: 20px; /* must specify pixel width. IE7/8 Quirks mode ignores opacity for an absolutely positioned item in a relative container with "overflow: visible". The marker in the colorBar
                  would not be drawn if its overflow is set to hidden. */
}
.jPicker .Bar .Map5, .jPicker .Bar .Map6 {
  height: 256px;
  width: 20px; /* must specify pixel width. IE7/8 Quirks mode ignores opacity for an absolutely positioned item in a relative container with "overflow: visible". The marker in the colorBar
                  would not be drawn if its overflow is set to hidden. */
}
.jPicker .Map .Map1, .jPicker .Map .Map2, .jPicker .Bar .Map6 {
  background-repeat: no-repeat;
}
.jPicker .Map .Map3, .jPicker .Bar .Map5 {
  background-repeat: repeat;
}
.jPicker .Bar .Map1, .jPicker .Bar .Map2, .jPicker .Bar .Map3, .jPicker .Bar .Map4 {
  background-repeat: repeat-x;
}
.jPicker .Map .Arrow {
  display: block;
  position: absolute;
}
.jPicker .Bar .Arrow {
  display: block;
  left: 0px; /* (arrow width / 2) - (element width / 2) - position arrows' center in elements' center */
  position: absolute;
}
.jPicker .Preview {
  font-size: 9px;
  padding: 5px 0px 0px 0px;
  text-align: center;
}
.jPicker .Preview div {
  border: 2px inset #eee;
  height: 62px;
  margin: 0px auto;
  padding: 0px;
  width: 62px;
}
.jPicker .Preview div span {
  border: 1px solid #000;
  display: block;
  height: 30px;
  margin: 0px auto;
  padding: 0px;
  width: 60px;
}
.jPicker .Preview .Active {
  border-bottom-width: 0px;
}
.jPicker .Preview .Current {
  border-top-width: 0px;
  cursor: pointer;
}
.jPicker input {
  font-size: 13px;
}
.jPicker .Button {
  text-align: center;
  padding: 0px 4px;
  width: 115px;
}
.jPicker .Button input {
  padding: 2px 0px;
  width: 100px;
}
.jPicker .Button .Ok {
  margin: 12px 0px 5px 0px;
}
.jPicker td {
  margin: 0px;
  padding: 0px;
}
.jPicker td.Radio {
  margin: 0px;
  padding: 0px;
  width: 31px;
}
.jPicker td.Radio input {
  margin: 0px 5px 0px 0px;
  padding: 0px;
}
.jPicker td.Text {
  font-size: 12px !important;
  height: 22px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  width: 70px;
}
.jPicker tr.Hex td.Text {
  width: 100px;
}
.jPicker td.Text input {
  background-color: #fff;
  border: 1px inset #aaa;
  height: 19px;
  margin: 0px 0px 0px 5px;
  text-align: left;
  width: 30px;
}
.jPicker td[class="Text"] input {
  height: 15px;
}
.jPicker tr.Hex td.Text input.Hex {
  width: 50px;
}
.jPicker tr.Hex td.Text input.AHex {
  width: 20px;
}
.jPicker .Grid {
  text-align: center;
  width: 114px;
}
.jPicker .Grid span.QuickColor {
  border: 1px inset #aaa;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  margin: 0px;
  padding: 0px;
  width: 19px;
}
.jPicker .Grid span[class="QuickColor"] {
  width: 17px;
}