/*
*  dhtmlxSuite v.5.1 Professional edition
*  (c) Dinamenta, UAB.
*
*  With customizations by Ecosys.  Search for "ECOSYS" to find all changes
*
*  5/3/2018: initial merge of old ECOSYS changes into this branch from 5.0.2
*  6/15/2018: fix for Firefox, background-color is invalid for the tree grid group band spacers. Changed to just background
*  10/31/2018: fix to prevent menu from throwing js error when grid is clicked on.
*  11/14/2018: Removed IE6 check in split grid code.
*
************************************************************************************/


/* ECOSYS SC:  this is a v36 style sheet, that happens to work in v412 and makes the grid look 
*  how it was before.The file was copied directly from v36 version of dhtmlxGrid.
*  Skin name is "eco"
*/
div.gridbox{
	overflow:hidden;
	text-align:left;
}
.dhx_sub_row
{
	background-color:white;
}
div.gridbox .xhdr{
	background-color:#D4D0C8;
}

div.gridbox table.obj{
	height:1px;
}
div.gridbox table.hdr td {
	line-height:normal;
	font-family:arial;
	font-size:12px;
	background-Color:#D4D0C8;
	border: 1px solid;
	border-color : white Gray Gray white;
	text-align: center;
	margin:0px;
	padding:5px 0px 5px 0px ;
	font-weight:normal;
	-moz-user-select:none;    
	-moz-user-select:-moz-none;    
    overflow:hidden;
    empty-cells:show;
	}
div.gridbox table.hdr td div.hdrcell{
	overflow:hidden;
}
div.gridbox table.obj td {
	border: 1px solid;
	border-color : white Gray Gray white;
	font-family:Arial;
	font-size:12px;
    -moz-user-select:none;
    -moz-user-select:-moz-none;   
    overflow:hidden;
    padding-top:0px;
    padding-bottom:0px;
    empty-cells:show;
	}
div.gridbox table.obj th, div.gridbox table.hdr th{
	padding:0px 0px 0px 0px ;
	margin:0px 0px 0px 0px ;
	}

div.gridbox table.row20px tr  td{
    height:20px;
    white-space: nowrap;
    padding:0px;
}
div.gridbox .objbox {
	background-color:white;
	position:relative;
    -webkit-overflow-scrolling: touch;
}
div.gridbox table.obj td span.space, div.gridbox table.obj td img.space{
	width:18px;
	}
div.gridbox table.obj tr.rowselected td.cellselected, div.gridbox table.obj td.cellselected {
	background-color:#d8d8d8;
	color:black;
	}
div.gridbox table.obj tr.rowselected td{
	background-color:#e1e0d7;
	color:black;
	}
div.gridbox table.obj td.editable{
    -moz-user-select:text;
    }
div.gridbox table.obj td.group_row{
    vertical-align:middle; font-family:Tahoma; font-size:10pt; font-weight:bold; height:30px;  border:0px;  border-bottom: 2px solid navy; 
    }    
    
.dhxgrid_sort_desc,
.dhxgrid_sort_asc {
	width: 9px;
	height: 8px;
	background-image: url("imgs/dhxgrid_eco/sort_desc.gif");
	background-repeat: no-repeat;
}
.dhxgrid_sort_asc {
	background-image: url("imgs/dhxgrid_eco/sort_asc.gif");
	background-repeat: no-repeat;
}    

.dragSpanDiv{ 	font-size : 12px; 	border: 1px gray solid; background-color:white; z-index:999; }
.dhx_combo_select{
    font-family:arial;
    font-size:12px;
    border:1px solid;
    border-color:black silver silver black;
    background-color:white;
    overflow:hidden;
    cursor:default;
    position:absolute;
    height:auto;
    z-index:600;
}
.dhx_combo_edit{
    width:100%;
    border:0px;
    padding:0px;
    padding-right:1px;
    margin:0px;
    font:12px arial;
    overflow:hidden;
}

.dhx_textarea{
    border:1px solid;
    border-color:black silver silver black;
    position:absolute;
	height:100px;
	z-index:600;
}
.dhx_clist{
    background-color:white;
    border:1px solid black;
    padding:2px 2px 2px 2px;
    z-index:300;
}
.gridDragLine{
   position:absolute;
   top:10px; left:0px;
   width:100%;
   height:2px;
   background-color:black;
   overflow:hidden;
}

/*paginal output*/
div.pagingBlock{
	font-size:12px;
	font-family:verdana,arial;
}
div.pagingBlock .pagingCurrentPage{
	font-weight:bold;
	cursor:default;
}
div.pagingBlock .pagingPage{
	cursor:pointer;
	text-decoration:underline;
}
span.recordsInfoBlock {
	font-size:12px;
	font-family:verdana,arial;
}
div.pagingBlock a{
	text-decoration:none;
	padding-right:2px;
	color:black;
	cursor:pointer;
}
div.pagingBlock a.dhx_not_active{
	text-decoration:none;
	cursor:default;
}
/*class for toolbar selectbox. used with pagingWT*/
.toolbar_select{
	font-size:10px;
}

/*block selection style*/
.dhtmlxGrid_selection {
   -moz-opacity: 0.5;
   filter: alpha(opacity = 50);
   background-color:yellow;
   opacity:0.5;
   border: 1px dotted black;
}

/* xp skin ------------------------------------------------------*/
div.gridbox_xp{
	border:1px solid lightgrey;
}
div.gridbox_xp .xhdr{
	background-image:url('imgs/dhxgrid_eco/header_bg_60.gif');
}
div.gridbox_xp table.hdr td {
	color:#616161;
	background-image:url('imgs/dhxgrid_eco/header_bg_60.gif');
	border:0px;
	text-align: center;
	margin:0px;
	padding:5px 0px 5px 0px ;
	font-weight:bold;
    -moz-user-select:none;
    -moz-user-select:-moz-none;    
    overflow:hidden;
	}
div.gridbox_xp table.hdr td div.hdrcell{
	border-left: 1px solid white;
	border-right: 1px solid gray;
	height:16px;
	white-space : nowrap;
	font-family:Arial;
	font-size:12px;
}
div.gridbox_xp table.obj td {
	border:0px;
	border-bottom: 1px solid lightgrey;
	border-right: 1px solid lightgrey;
	font-family:Arial;
	font-size:12px;
    -moz-user-select:none;
    -moz-user-select:-moz-none;    
    overflow:hidden;
    padding-top:0px;
    padding-bottom:0px;
	}
div.gridbox_xp table.obj tr.rowselected td{
	background-color:whitesmoke;
	color:black;
	}
div.gridbox_xp table.obj tr.rowselected td.cellselected, div.gridbox_xp table.obj td.cellselected {
	background-color:whitesmoke;
	}
div.gridbox_xp table.row20px tr  td{
    height:22px;
    white-space: nowrap;
    padding:1px;
}

/* gray skin --------------------------------------------------*/

div.gridbox_gray {
	border:1px solid gray;
	background-color:#D4D0C8;
}

/* mt skin ------------------------------------------------------*/
div.gridbox_mt{
	border:1px solid lightgrey;
}
div.gridbox_mt .dhx_sub_row
{
	background-color:transparent;
}
div.gridbox_mt .xhdr{
	background-image:url('imgs/dhxgrid_eco/header_bg.gif');
}
div.gridbox_mt .xhdr_last{
	border:0px;
	border-bottom: 1px solid lightgrey;
	border-left: 1px solid lightgrey;
}
div.gridbox_mt table.hdr td {
	color:#616161;
	border:0px;
	border-bottom: 1px solid lightgrey;
	border-left: 1px solid lightgrey;
	text-align: center;
	margin:0px;
	background-image:url('imgs/dhxgrid_eco/header_bg.gif');
	padding: 0px 0px 0px 0px;
	-moz-user-select:none;
	-moz-user-select:-moz-none;    
	overflow:hidden;
	}
div.gridbox_mt table.hdr td div.hdrcell{
	height:16px;
	white-space : nowrap;
	font-family:Verdana;
	font-size:12px;
}
div.gridbox_mt table.obj td {
	border:0px;
	border-bottom: 1px solid lightgrey;
	border-right: 0px solid lightgrey;
	font-family:Verdana;
	font-size:12px;
    -moz-user-select:none;
    -moz-user-select:-moz-none;    
    overflow:hidden;
    padding-top:0px;
    padding-bottom:0px;
	}
div.gridbox_mt table.obj tr.rowselected td{
	background-color:#D6D3FA;
	color:black;
	}
div.gridbox_mt table.obj tr.rowselected td.cellselected, div.gridbox_mt table.obj td.cellselected {
	background-color:#D6D3FA;
	}
div.gridbox_mt table.row20px tr  td{
    height:22px;
    white-space: nowrap;
    padding:1px;
}

/*------------------------------------------------------------*/


div.gridbox div.ftr{
  position:absolute;
  left:0px;
  bottom:1px;
  width:100%;
  overflow:hidden;
}

div.gridbox div.ftr td {
		padding:0px;
		padding-left:10px;
		padding-right:5px;
		border-top:1px solid gray;
		border-right:1px solid gray;
		background-color:#ffffcc;
		font-style : italic;
		font-family:arial;
		font-size:12px;
		overflow:hidden;
	}

div.gridbox table.hdr td.columnTargetR div.hdrcell{
	border-right:3px double #FF6600;
	border-left:3px solid #D4D0C8;
}

div.gridbox table.hdr td.columnTargetL div.hdrcell{
	border-right:3px solid #D4D0C8;
	border-left:3px double #FF6600;
}

.dhx_dragColDiv{
	font-family:Arial;
	font-size:12px;
	background-color:#D4D0C8;
	border: 1px solid;
	border-color : white Gray Gray white;
	text-align: center;
	margin:0px;
	padding:5px 20px 5px 20px ;
	font-weight:normal;
	filter:alpha(opacity=75);
	-moz-opacity:0.75;
	opacity:0.75;
}


/*light*/
div.gridbox_light {
  border:1px solid  #c2d5dc;
}
div.gridbox_light .xhdr{
	background-image:url('imgs/dhxgrid_eco/skin_light_header.png');
}
div.gridbox_light .xhdr_last{
	border: 1px solid;
	border-color : #FDFDFD #93AFBA #93AFBA #FDFDFD;
}

div.gridbox_light table.hdr{
	background-image:url('imgs/dhxgrid_eco/skin_light_header.png');
}

div.gridbox_light table.hdr td {
	border: 1px solid;
	border-color : #FDFDFD #93AFBA #93AFBA #FDFDFD;
	background-color:transparent;
	font-family:Tahoma;
	font-size:11px;
	font-weight:bold;
	color:#055A78;
	vertical-align:top;
	text-align:left;
}
div.gridbox_light table.hdr td div.hdrcell{
	width:auto;
	padding-left:10px;
}
div.gridbox_light table.hdr .filter{
	padding-left:0px !important;
	text-align:center;
	-moz-user-select:text;
}
div.gridbox_light table.obj td {
	border-width: 0px 1px 0px 1px;
	border-left: 1px solid white;
	border-right: 1px solid #D6D6D6;
	font-family:Tahoma;
	font-size:11px;
	padding-right:4px;
	padding-left:4px;
}
div.gridbox_light table.obj{
	border-bottom: 1px solid #D6D6D6;
}
div.gridbox_light table.row20px tr td {
	padding-right:4px;
	padding-left:4px;
}
div.gridbox_light .dhx_combo_edit{
  font-family:Tahoma;
	font-size:11px;
}
div.gridbox_light table.obj tr.rowselected td{
	background-color:#ededed;
	color:black;
	}
div.gridbox_light table.obj tr.rowselected td.cellselected, div.gridbox_light table.obj td.cellselected {
	background-color:#ededed;
	}
div.gridbox_light .odd_light{
	background-color:#E5F2F8;
}

div.gridbox_light div.ftr td {
	empty-cells:show;
}


/*modern*/
div.gridbox_modern {
  border:1px solid  #D6D6D6;
}
div.gridbox_modern .dhx_sub_row
{
	background-color:transparent;
}
div.gridbox_modern .xhdr{
	background-image:url('imgs/dhxgrid_eco/skin_modern_header.png');
}
div.gridbox_modern .xhdr_last{
	border: 1px solid;
	border-color : #FDFDFD #B5B5B5 #B5B5B5 #FDFDFD;
}
div.gridbox_modern table.hdr{
	background-image:url('imgs/dhxgrid_eco/skin_modern_header.png');
}

div.gridbox_modern table.hdr td {
	border-right:1px solid #B5B5B5;
	border-left:1px solid #FDFDFD;
	border-top:1px solid #FDFDFD;
	border-bottom:1px solid #B5B5B5;

	background-color:transparent;
	font-family:Tahoma;
	font-size:11px;
	font-weight:bold;
	color:#055A78;
	vertical-align:top;
	text-align:left;
}
div.gridbox_modern table.hdr td div.hdrcell{
	width:auto;
	padding-left:10px;
}
div.gridbox_modern table.hdr .filter{
	padding-left:0px !important;
	text-align:center;
}
div.gridbox_modern table.obj td {
	border: 0px solid;
	font-family:Tahoma;
	font-size:11px;
	padding-right:4px;
	padding-left:4px;
}
div.gridbox_modern table.row20px tr td {
	padding-right:4px;
	padding-left:4px;
}
div.gridbox_modern .dhx_combo_edit{
  font-family:Tahoma;
	font-size:11px;
}
div.gridbox_modern table.obj tr.rowselected td{
	background-color:#9ac2e5;
	color:black;
	}
div.gridbox_modern table.obj tr.rowselected td.cellselected, div.gridbox_modern table.obj td.cellselected {
	background-color:#9ac2e5;
	}
div.gridbox_modern .odd_modern{
	background-color:#EDEDED;
}
div.gridbox_modern div.ftr td {
		padding:0px;
		padding-left:10px;
		padding-right:5px;
		border-top:0px solid gray;
		border-right:0px solid gray;
		background-color:#ffffcc;
		font-style : italic;
		font-family:arial;
		font-size:12px;
	}



/*clear*/
div.gridbox_clear .xhdr{
	background-color:transparent;
}
div.gridbox_clear div.topMumba{
     position:absolute;
	 left:0px;
	 width:100%;
	 height:3px;
	 background-image:url('imgs/dhxgrid_eco/skinC_header.png');
	 overflow:hidden;
	 padding:0px;
	 margin:0px;
}
div.gridbox_clear div.bottomMumba{
     position:absolute;
	 left:0px;
	 width:100%;
	 height:3px;
	 background-image:url('imgs/dhxgrid_eco/skinD_header.png');
	 overflow:hidden;
}
div.gridbox_clear div.bottomMumba img,div.gridbox_clear div.topMumba img{
	border:0px;
	position:absolute;
	top:0px;
}

div.gridbox_clear{
	padding-left: 10px;
	padding-right: 10px;
}

div.gridbox_clear table.hdr td {
	border:0px;
	background-color:transparent;
	font-family:Tahoma;
	font-size:11px;
	font-weight:bold;
	color:#055A78;
	vertical-align:top;
	text-align:left;
}
div.gridbox_clear table.hdr td div.hdrcell{
	width:auto;
	padding-left:10px;
	padding-bottom:2px;
}
div.gridbox_clear table.hdr .filter{
	padding-left:0px !important;
	text-align:center;
}
div.gridbox_clear table.obj td {
    border-width:  0px 1px 0px 0px ;
	border-color:#D6D6D6;
	font-family:Tahoma;
	font-size:11px;
	padding-right:4px;
	padding-left:4px;
}
div.gridbox_clear table.row20px tr td {
	padding-right:4px;
	padding-left:4px;
}
div.gridbox_clear .dhx_combo_edit{
  font-family:Tahoma;
	font-size:11px;
}
div.gridbox_clear .odd_clear{
	background-color:#E5F2F8;
}

div.gridbox_clear div.ftr td {
		padding:0px;
		padding-left:10px;
		padding-right:5px;
		border-top:1px solid gray;
		border-right:0px solid gray;
		background-color:#ffffcc;
		font-style : italic;
		font-family:arial;
		font-size:12px;
	}


/*sb dark*/

div.gridbox_sbdark .objbox {
    background: #313131 !important;
}

div.gridbox_sbdark .xhdr{
	background-color:#313131;
}
div.gridbox_sbdark .xhdr_last{
	border: 1px solid;
	border-color : #474948 #202220 #202220 #202220;
}

div.gridbox_sbdark {      
      background: #313131 !important; 
}

div.gridbox_sbdark table {
    border-collapse: collapse;
}

div.gridbox_sbdark table.hdr tr  {
    border-top: 1px solid #202220;
}

div.gridbox_sbdark table.hdr, 
div.gridbox_sbdark table.hdr td {
    border-right:1px solid #202220;
    border-left:1px solid #202220;
    border-top: 1px solid #474948;
    border-bottom:1px solid #202220;
    background-color: #313131;
    
    font-size:11px; 
    color:#8A8F84;
    vertical-align:top;
    text-align:left;
    padding: 2px 5px;
}

div.gridbox_sbdark .hdrcell {
    padding-left: 0px !important;
    font-family: 'Lucida Sans Unicode','Tahoma'; 
}

div.gridbox_sbdark table.hdr td div.hdrcell{
    width:auto;
    padding-left:10px;
}

div.gridbox_sbdark table.obj td {
    border-width: 0px 1px 0px 1px;
    border-left: 1px solid #202220;
    border-right: 1px solid #EDF3F0;
    font-family: 'Consolas','Lucida Sans Unicode','Tahoma';
    font-size:11px;    
}

div.gridbox_sbdark table.row20px tr td {
    padding: 0px 5px !important; 
    text-indent:1px;
}

div.gridbox_sbdark .dhx_combo_edit{
    font-family: 'Lucida Sans Unicode','Tahoma';
    font-size:11px;
}

div.gridbox_sbdark table.obj tr.rowselected td, 
div.gridbox_sbdark table.obj tr:hover, 
div.gridbox_sbdark .odd_light:hover {
    background-color: #8A8F84;
    color: white !important;
}

div.gridbox_sbdark table.obj tr.rowselected td.cellselected , 
div.gridbox_sbdark table.obj td.cellselected {
    background-color:#8A8F84;
}

div.gridbox_sbdark .cellselected {
    background-color: #6e6f64 !important;    
}

div.gridbox_sbdark .ev_sbdark {
    background-color: #FFFFFF;
}

div.gridbox_sbdark .odd_sbdark {
    background-color:#EDF3F0;
} 

.dhtmlx_live_validation_error{
	background-color:#FFE0E0 !important;
}
.dhtmlx_validation_error{
    border-bottom:2px solid red !important;
}
.dhx_header_cmenu{
   background-color:#ffffff;
   border:2px outset silver;
   z-index:2;
  }
.dhx_header_cmenu_item{
  white-space:nowrap;
 }
div.gridbox_dhx_skyblue div.ftr td{
	text-align:right;
	background-image:url('imgs/dhxgrid_eco/sky_blue_grid.gif');
	border-color:#A4BED4;
}

div.gridbox td.filter input, div.gridbox td.filter select{
	width:90%; font-size:8pt; font-family:Tahoma; -moz-user-select:text;
}
/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhtmlxcalendar_ifr {
	position: absolute;
	overflow: hidden;
	background-color: white;
}
.dhtmlxcalendar_dhx_skyblue {
	position: absolute;
	display: block;
	background-color: white;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
}
.dhtmlxcalendar_dhx_skyblue ul.dhtmlxcalendar_line {
	position: relative;
	display: block;
	clear: both;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	width: 211px;
}
.dhtmlxcalendar_dhx_skyblue ul.dhtmlxcalendar_line li {
	float: left;
	position: relative;
	list-style-type: none;
	list-style-image: none;
	text-align: center;
	vertical-align: middle;
	cursor: default;
	overflow: hidden;
	margin: 0px;
	padding: 0px;
}
.dhtmlxcalendar_dhx_skyblue.dhtmlxcalendar_in_input {
	box-shadow: 0 0 6px rgba(0,0,0,0.25);
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont {
	position: relative;
	display: block;
	width: 211px;
	height: 25px;
	margin: 0px;
	background-color: #e2efff;
	background: linear-gradient(#e2efff,#d3e7ff);
	background: -webkit-linear-gradient(#e2efff,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e2efff,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	border: 1px solid #a4bed4;
	overflow: hidden;
	color: black;
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	-o-user-select: text;
	user-select: text;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr {
	width: 211px;
	height: 25px;
	line-height: 25px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr div.dhtmlxcalendar_month_arrow {
	position: absolute;
	top: 0;
	width: 18px;
	height: 25px;
	text-align: center;
	color: inherit;
	background-position: center center;
	background-repeat: no-repeat;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr div.dhtmlxcalendar_month_arrow.dhtmlxcalendar_month_arrow_left {
	left: 4px;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_left.gif");
	opacity: 0.8;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr div.dhtmlxcalendar_month_arrow.dhtmlxcalendar_month_arrow_left_hover {
	left: 4px;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_left.gif");
	opacity: 1;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr div.dhtmlxcalendar_month_arrow.dhtmlxcalendar_month_arrow_right {
	right: 4px;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_right.gif");
	opacity: 0.8;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr div.dhtmlxcalendar_month_arrow.dhtmlxcalendar_month_arrow_right_hover {
	right: 4px;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_right.gif");
	opacity: 1;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr span.dhtmlxcalendar_month_label_month,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_month_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_month_hdr span.dhtmlxcalendar_month_label_year {
	position: relative;
	font-weight: bold;
	color: #34404b;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_today,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_clear {
	display: none;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_today {
	float: right;
	margin-right: 8px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_clear {
	float: right;
	margin-right: 74px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_today,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_clear {
	float: right;
	margin-right: 8px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_days_cont {
	position: relative;
	display: block;
	width: 211px;
	margin: 0px;
	padding-bottom: 1px;
	border-left: 1px solid #a4bed4;
	border-right: 1px solid #a4bed4;
	border-bottom: 1px solid #cbd9e4;
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	-o-user-select: text;
	user-select: text;
	overflow: hidden;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line {
	border-top: 1px solid white;
	padding-left: 1px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li {
	width: 29px;
	height: 19px;
	line-height: 19px;
	margin-right: 1px;
	font-size: 9px;
	background-color: #ecf4ff;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_day_weekday_cell,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_day_weekday_cell_first {
	color: #c66200;
	background-color: #ffe6ae;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont {
	position: relative;
	display: block;
	width: 211px;
	margin: 0px;
	padding-bottom: 1px;
	border-left: 1px solid #a4bed4;
	border-right: 1px solid #a4bed4;
	border-bottom: 1px solid #a4bed4;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow: hidden;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line {
	border-top: 1px solid white;
	padding-left: 1px;
	height: 26px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li {
	color: #909090;
	background-color: white;
	border: 1px solid white;
	width: 27px;
	height: 24px;
	line-height: 23px;
	margin-right: 1px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li div.dhtmlxcalendar_label {
	width: 100%;
	height: 100%;
	text-align: center;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_weekend {
	border-color: #cccccc;
	background-color: #f7f7f7;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_weekend_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_weekend_dis {
	color: #b2b2b2;
	background-color: #f0f0f0;
	border-color: #f0f0f0;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_holiday,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_weekend_holiday {
	color: red;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_holiday,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_weekend_holiday {
	color: red;
	border-color: #cccccc;
	background-color: #f7f7f7;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_holiday_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_weekend_holiday_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_holiday_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_weekend_holiday_dis {
	color: red;
	background-color: #f0f0f0;
	border-color: #f0f0f0;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_hover,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_weekend_hover,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_hover,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_weekend_hover {
	border-color: #cccccc;
	background-color: #f7f7f7;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_holiday_hover,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_weekend_holiday_hover,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_holiday_hover,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_date_weekend_holiday_hover {
	color: red;
	border-color: #cccccc;
	background-color: #f7f7f7;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month {
	color: black;
	background-color: #ecf4ff;
	border-color: #ecf4ff;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_weekend {
	color: #c66200;
	background-color: #fff0d2;
	border-color: #fff0d2;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date {
	color: black;
	background-color: #b5deff;
	border-color: #a1ceed;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_weekend {
	color: #c66200;
	background-color: #ffdc90;
	border-color: #ffce65;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_weekend_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_weekend_dis {
	color: #b2b2b2;
	background-color: #f0f0f0;
	border-color: #f0f0f0;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_holiday {
	color: red;
	background-color: #ecf4ff;
	border-color: #ecf4ff;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_weekend_holiday {
	color: red;
	background-color: #fff0d2;
	border-color: #fff0d2;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_holiday {
	color: red;
	background-color: #b5deff;
	border-color: #a1ceed;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_weekend_holiday {
	color: red;
	background-color: #ffdc90;
	border-color: #ffce65;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_holiday_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_weekend_holiday_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_holiday_dis,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_weekend_holiday_dis {
	color: red;
	background-color: #f0f0f0;
	border-color: #f0f0f0;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_hover {
	color: black;
	background-color: #d9eeff;
	border-color: #a1ceed;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_weekend_hover {
	color: #c66200;
	background-color: #ffe9bb;
	border-color: #ffce65;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_hover {
	color: black;
	background-color: #b5deff;
	border-color: #a1ceed;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_weekend_hover {
	color: #c66200;
	background-color: #ffdc90;
	border-color: #ffce65;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_holiday_hover {
	color: red;
	background-color: #d9eeff;
	border-color: #a1ceed;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_weekend_holiday_hover {
	color: red;
	background-color: #ffe9bb;
	border-color: #ffce65;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_holiday_hover {
	color: red;
	background-color: #b5deff;
	border-color: #a1ceed;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_cell_month_date_weekend_holiday_hover {
	color: red;
	background-color: #ffdc90;
	border-color: #ffce65;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line div.dhtmlxcalendar_label.dhtmlxcalendar_label_title {
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_mark.gif");
	background-position: top right;
	background-repeat: no-repeat;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont {
	position: relative;
	display: block;
	width: 211px;
	height: 25px;
	margin: 0px;
	border-left: 1px solid #a4bed4;
	border-right: 1px solid #a4bed4;
	border-bottom: 1px solid #a4bed4;
	background-color: #e2efff;
	background: linear-gradient(#e2efff,#d3e7ff);
	background: -webkit-linear-gradient(#e2efff,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e2efff,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow: hidden;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont ul.dhtmlxcalendar_line li {
	width: 211px;
	height: 25px;
	line-height: 24px;
	color: #34404b;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr {
	text-align: left;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont ul.dhtmlxcalendar_line li div.dhtmlxcalendar_time_img {
	position: absolute;
	left: 8px;
	top: 6px;
	width: 13px;
	height: 13px;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_clock.gif");
	background-position: center center;
	background-repeat: no-repeat;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_hours {
	margin-left: 26px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time ul.dhtmlxcalendar_line li div.dhtmlxcalendar_time_img {
	left: 70px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr {
	text-align: center;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_hours {
	margin-left: 0px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr div.dhtmlxcalendar_time_img,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_hours,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_minutes,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_today ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span.dhtmlxcalendar_label_colon {
	display: none;
}
.dhtmlxcalendar_dhx_skyblue span.dhtmlxcalendar_label_colon {
	padding: 0px 4px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_cover {
	position: absolute;
	left: 1px;
	background-color: white;
	opacity: 0.5;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj {
	position: absolute;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_selector_obj_arrow {
	position: absolute;
	bottom: auto;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 9px;
	overflow: hidden;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_selector_top.gif");
	background-position: top center;
	background-repeat: no-repeat;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours div.dhtmlxcalendar_selector_obj_arrow {
	background-position: -15px top;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 div.dhtmlxcalendar_selector_obj_arrow {
	background-position: -57px top;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes div.dhtmlxcalendar_selector_obj_arrow {
	background-position: -53px top;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table {
	border: 1px solid #a4bed4;
	background-color: white;
	border-top: none;
	margin-top: 9px;
	box-shadow: 0 0 3px rgba(0,0,0,0.35);
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_left {
	width: 17px;
	text-align: center;
	border-top: 1px solid white;
	padding: 0px;
	margin: 0px;
	background-color: #ecf4ff;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_left.gif");
	background-position: center center;
	background-repeat: no-repeat;
	cursor: default;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_left.dhtmlxcalendar_selector_cell_left_hover {
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_left.gif");
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle {
	border-top: white 1px solid;
	border-width: 1px 0px 0px 0px;
	cursor: default;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_right {
	width: 17px;
	text-align: center;
	border-top: 1px solid white;
	border-width: 1px 0px 0px 0px;
	background-color: #ecf4ff;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_right.gif");
	background-position: center center;
	background-repeat: no-repeat;
	cursor: default;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_right.dhtmlxcalendar_selector_cell_right_hover {
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_arrow_right.gif");
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul {
	display: block;
	clear: both;
	margin: 0px 0px 0px 1px;
	padding: 0px;
	overflow: hidden;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul li {
	float: left;
	font-family: Tahoma, Helvetica;
	font-size: 10px;
	color: black;
	list-style-type: none;
	list-style-image: none;
	text-align: center;
	vertical-align: middle;
	margin: 0px 1px 0px 0px;
	padding: 0px;
	background-color: #e9f3ff;
	border: 1px solid #e9f3ff;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: default;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul li.dhtmlxcalendar_selector_cell_active {
	background-color: #b5deff;
	border-color: #a1ceed;
	color: black;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul li.dhtmlxcalendar_selector_cell_hover {
	background-color: #d9eeff;
	border-color: #a1ceed;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_month table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_left,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_month table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_right {
	display: none;
	width: 0px;
	border: 0px solid white;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line {
	height: 22px;
	border-top: none;
	border-bottom: 1px solid white;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line li.dhtmlxcalendar_selector_cell {
	width: 33px;
	height: 20px;
	line-height: 19px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours div.dhtmlxcalendar_selector_obj_arrow,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 div.dhtmlxcalendar_selector_obj_arrow,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes div.dhtmlxcalendar_selector_obj_arrow {
	top: auto;
	bottom: 0px;
	background-image: url("imgs/dhxcalendar_skyblue/dhxcalendar_selector_bottom.gif");
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line {
	height: 19px;
	border-top: none;
	border-bottom: 1px solid white;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line li.dhtmlxcalendar_selector_cell,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line li.dhtmlxcalendar_selector_cell,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_middle ul.dhtmlxcalendar_selector_line li.dhtmlxcalendar_selector_cell {
	width: 21px;
	height: 17px;
	line-height: 16px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_left,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_right,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_left,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_right,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_left,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes table.dhtmlxcalendar_selector_table td.dhtmlxcalendar_selector_cell_right {
	display: none;
	width: 0px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours table.dhtmlxcalendar_selector_table,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 table.dhtmlxcalendar_selector_table,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes table.dhtmlxcalendar_selector_table {
	margin-top: 0px;
	margin-bottom: 9px;
	border-top: 1px solid #a4bed4;
	border-bottom: none;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_area_selector_month,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_area_selector_year,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_area_selector_hours,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_area_selector_hours2,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_area_selector_minutes,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_area_selector_minutes4,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj div.dhtmlxcalendar_area_selector_minutes5 {
	display: none;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_month div.dhtmlxcalendar_area_selector_month,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_year div.dhtmlxcalendar_area_selector_year,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours div.dhtmlxcalendar_area_selector_hours,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_hours2 div.dhtmlxcalendar_area_selector_hours,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes div.dhtmlxcalendar_area_selector_minutes,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes div.dhtmlxcalendar_area_selector_minutes4,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_selector_obj.dhtmlxcalendar_selector_minutes div.dhtmlxcalendar_area_selector_minutes5 {
	display: block;
}
.dhtmlxcalendar_dhx_skyblue span.dhtmlxcalendar_label_minutes span.dhtmlxcalendar_selected_date {
	border-bottom: 2px solid red;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell_wn,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell_wn {
	display: none;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_wn div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell {
	width: 26px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_wn div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell {
	width: 24px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_wn div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell_first,
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_wn div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell {
	width: 26px;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_wn div.dhtmlxcalendar_days_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell_wn {
	display: block;
	width: 21px;
	color: #638eb1;
	background-color: #d9eaff;
}
.dhtmlxcalendar_dhx_skyblue div.dhtmlxcalendar_wn div.dhtmlxcalendar_dates_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_cell_wn {
	display: block;
	width: 19px;
	color: #638eb1;
	background-color: #d9eaff;
	border-color: #d9eaff;
	font-size: 10px;
}
div.dhtmlxcalendar_skin_detect {
	position: absolute;
	display: block;
	visibility: hidden;
	left: -100px;
	top: 0px;
	width: 10px;
	height: 10px;
	margin: 0px;
	padding: 0px;
	border: none;
	overflow: hidden;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhtmlx_message_area {
	position: fixed;
	right: 5px;
	width: 250px;
	z-index: 1000;
	padding: 0px 0px;
}
.dhtmlx-info {
	color: #444;
	border-radius: 4px;
	min-width: 120px;
	background: white;
	font-size: 12px;
	font-family: Tahoma;
	z-index: 10000;
	margin: 0px 5px 5px 5px;
	border: 1px solid #e6d8bc;
	box-shadow: 0px 0px 5px #ccc;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}
.dhtmlx-info div {
	padding: 9px 9px 9px 15px;
	margin: 1px;
	background-color: #FFF0D2;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}
.dhtmlx-info.hidden {
	box-shadow: none;
	border: 1px solid transparent !important;
	border-bottom: none;
	margin-top: 0px;
	margin-bottom: 0px;
	overflow: hidden;
}
.dhtmlx-info.hidden div {
	height: 0px;
	border-top: none;
	border-bottom: none;
	padding-bottom: 0px;
	padding-top: 0px;
	overflow: hidden;
}
.dhtmlx-error {
	border: 1px solid #e64949;
	color: #fff;
}
.dhtmlx-error div {
	padding: 9px 9px 9px 18px;
	margin: 1px;
	background-color: #FF5252;
}
.dhtmlx_modal_box {
	overflow: hidden;
	display: inline-block;
	min-width: 300px;
	width: 300px;
	text-align: center;
	position: fixed;
	background-color: #fff;
	z-index: 20000;
	-moz-box-shadow: 0px 0px 5px #AAAAAA;
	-webkit-box-shadow: 0px 0px 0px #AAAAAA;
	box-shadow: 0px 0px 5px #AAAAAA;
	border: 1px solid #a4bed4;
	border-radius: 5px;
}
.dhtmlx_popup_title {
	padding: 10px 0;
	font-size: 12px;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-top-left-radius: 3px;
	border-radius-top-right: 3px;
	line-height: 16px;
	font-family: Tahoma;
	font-weight: bold;
}
.dhtmlx-info,
.dhtmlx_popup_title,
.dhtmlx_popup_button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	-moz-user-select: -moz-none;
	cursor: pointer;
}
.dhtmlx_popup_text {
	font-size: 13px;
	font-family: Tahoma;
	color: #444;
	min-height: 30px;
	padding: 20px 10px 10px 10px !important;
	overflow: hidden;
}
.dhtmlx_popup_controls {
	font-family: Tahoma;
	font-weight: bold;
	padding: 10px 10px 17px 10px !important;
}
.dhtmlx_popup_button {
	font-size: 12px;
	font-family: Tahoma;
	font-weight: normal;
	min-width: 120px;
	width: 120px;
	height: 26px;
	display: inline-block;
	margin: 0 5px;
	border-radius: 2px;
}
.dhtmlx_popup_button div {
	line-height: 26px;
}
div.dhx_modal_cover {
	background-color: #000;
	cursor: default;
	opacity: 0.2;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
	position: fixed;
	z-index: 19999;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	border: none;
	zoom: 1;
}
.dhtmlx_popup_button {
	color: #34404b;
	border: 1px solid #a4bed4;
	background-color: #e7f1ff;
	background-image: -moz-linear-gradient(center bottom,#e2efff 0%,#d3e7ff 12%,#e7f1ff 100%);
	background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0.00,#e7f1ff),color-stop(0.88,#d3e7ff),color-stop(1.00,#e2efff));
	background: -o-linear-gradient(top,#e2efff,#d3e7ff 12%,#e7f1ff);
	background: linear-gradient(top,#e2efff,#d3e7ff 12%,#e7f1ff);
	background: -ms-linear-gradient(top,#e2efff 0%,#d3e7ff 12%,#e7f1ff 100%);
}
.dhtmlx_popup_button:active,
.dhtmlx_popup_button:focus {
	box-shadow: inset 0 0 2px #aaaaaa;
	background: #f1f7ff;
	background: -moz-linear-gradient(top,#d2e7fe 0%,#d3e7ff 88%,#e7f1ff 100%);
	background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#d2e7fe),color-stop(88%,#d3e7ff),color-stop(100%,#e7f1ff));
	background: -o-linear-gradient(top,#d2e7fe 0%,#d3e7ff 88%,#e7f1ff 100%);
	background: -ms-linear-gradient(top,#d2e7fe 0%,#d3e7ff 88%,#e7f1ff 100%);
	background: linear-gradient(top,#d2e7fe 0%,#d3e7ff 88%,#e7f1ff 100%);
}
.dhtmlx_popup_title {
	box-shadow: inset 0px 0px 1px 1px #ffffff;
	display: block;
}
.dhtmlx-alert-error .dhtmlx_popup_title,
.dhtmlx-confirm-error .dhtmlx_popup_title {
	color: white;
	border: 1px solid #f17373;
	background: #f17373;
	background: -webkit-linear-gradient(top,#ff7c7c,#f17373 88%,#ff7361);
	background: -moz-linear-gradient(top,#ff7c7c,#f17373 88%,#ff7361);
	background: -o-linear-gradient(top,#ff7c7c,#f17373 88%,#ff7361);
	background: linear-gradient(top,#ff7c7c,#f17373 88%,#ff7361);
	background: -ms-linear-gradient(top,#ff7c7c 0%,#f17373 88%,#ffbc75 100%);
}
.dhtmlx-alert-error.dhtmlx_modal_box,
.dhtmlx-confirm-error.dhtmlx_modal_box {
	border: 1px solid #f17373;
}
.dhtmlx-alert-error .dhtmlx_popup_button,
.dhtmlx-confirm-error .dhtmlx_popup_button {
	border: 1px solid #f17373;
	color: #ffffff;
	font-weight: normal;
	background: linear-gradient(to bottom,#ff8e8e,#f17373);
}
.dhtmlx-alert-error .dhtmlx_popup_button:active,
.dhtmlx-alert-error .dhtmlx_popup_button:focus,
.dhtmlx-confirm-error .dhtmlx_popup_button:active,
.dhtmlx-confirm-error .dhtmlx_popup_button:focus {
	background: linear-gradient(to bottom,#ff8e8e,#f17373);
}
.dhtmlx-alert-warning .dhtmlx_popup_title,
.dhtmlx-confirm-warning .dhtmlx_popup_title {
	color: #000;
	border: 1px solid #d2b07f;
	background: #ff9f37;
	background: -webkit-linear-gradient(top,#ffc786,#ff9523 88%,#ffbc75);
	background: -moz-linear-gradient(top,#ffc786,#ff9523 88%,#ffbc75);
	background: -o-linear-gradient(top,#ffc786,#ff9523 88%,#ffbc75);
	background: linear-gradient(top,#ffc786,#ff9523 88%,#ffbc75);
	background: -ms-linear-gradient(top,#ffc786 0%,#ff9523 88%,#ffbc75 100%);
}
.dhtmlx-alert-warning .dhtmlx_popup_controls,
.dhtmlx-confirm-warning .dhtmlx_popup_controls {
	border: 1px solid #d5d5d5;
	border-width: 0 1px 1px 1px;
}
.dhtmlx-alert-warning .dhtmlx_popup_text,
.dhtmlx-confirm-warning .dhtmlx_popup_text {
	border: 1px solid #d5d5d5;
	border-width: 0 1px 0 1px;
}
.dhtmlx-alert .dhtmlx_popup_title,
.dhtmlx-confirm .dhtmlx_popup_title {
	color: black;
	border: 1px solid #a4bed4;
	background: #e7f1ff;
	background: -moz-linear-gradient(center bottom,#e2efff 0%,#d3e7ff 12%,#e7f1ff 100%);
	background: -webkit-gradient(linear,left top,left bottom,color-stop(0.00,#e7f1ff),color-stop(0.88,#d3e7ff),color-stop(1.00,#e2efff));
	background: -o-linear-gradient(top,#e2efff,#d3e7ff 12%,#e7f1ff);
	background: linear-gradient(top,#e2efff,#d3e7ff 12%,#e7f1ff);
	background: -ms-linear-gradient(top,#e2efff 0%,#d3e7ff 12%,#e7f1ff 100%);
}
.dhtmlx-alert .dhtmlx_popup_controls,
.dhtmlx-confirm .dhtmlx_popup_controls {
	border: 1px solid #a4bed4;
	border-width: 0 1px 1px 1px;
}
.dhtmlx-alert .dhtmlx_popup_text,
.dhtmlx-confirm .dhtmlx_popup_text {
	border: 1px solid #a4bed4;
	border-width: 0 1px 0 1px;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

div.dhxcombo_dhx_skyblue {
	position: relative;
	border: 1px solid #a4bed4;
	overflow: hidden;
	vertical-align: middle;
	background-color: white;
	font-size: 1px;
	height: 22px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
div.dhxcombo_dhx_skyblue input.dhxcombo_input {
	position: relative;
	top: 0px;
	left: 1px;
	height: 22px;
	line-height: 21px;
	*height: 20px;
	*line-height: 19px;
	border: 0px solid white;
	outline: 0px solid white;
	padding: 0px;
	margin: 0px 0px 0px 2px;
	background-color: white;
	font-family: Tahoma, Helvetica;
	font-size: 12px;
	color: black;
	vertical-align: middle;
}
div.dhxcombo_dhx_skyblue input.dhxcombo_input::-ms-clear {
	display: none;
}
div.dhxcombo_dhx_skyblue div.dhxcombo_select_button {
	position: absolute;
	width: 18px;
	height: 18px;
	top: 1px;
	right: 1px;
	font-size: 1px;
	border: 1px solid #a4bed4;
	background-color: #e2efff;
	background: linear-gradient(#e2efff,#d3e7ff);
	background: -webkit-linear-gradient(#e2efff,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e2efff,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	border-radius: 2px;
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow: hidden;
}
div.dhxcombo_dhx_skyblue div.dhxcombo_select_button div.dhxcombo_select_img {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url("imgs/dhxcombo_skyblue/dhxcombo_arrow_down.gif");
	background-repeat: no-repeat;
	background-position: center center;
}
div.dhxcombo_dhx_skyblue div.dhxcombo_top_image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 26px;
	height: 22px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow: hidden;
	cursor: default;
}
div.dhxcombolist_dhx_skyblue {
	position: absolute;
	font-family: Tahoma, Helvetica;
	font-size: 12px;
	color: black;
	border: 1px solid #a4bed4;
	box-shadow: 0 2px 3px #ccc;
	background-color: #e7f1ff;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow-x: none;
	overflow-y: auto;
	-moz-transition: height 0.15s ease 0s;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
div.dhxcombolist_dhx_skyblue div.dhxcombo_option {
	position: relative;
	font-size: inherit;
	height: 20px;
	line-height: 19px;
	border-top: 1px solid #e7f1ff;
	border-bottom: 1px solid #e7f1ff;
	vertical-align: middle;
	overflow: hidden;
	cursor: default;
}
div.dhxcombolist_dhx_skyblue div.dhxcombo_option.dhxcombo_option_selected {
	background-color: #b5deff !important;
	border-top: 1px solid #a1ceed !important;
	border-bottom: 1px solid #a1ceed !important;
	color: black;
}
div.dhxcombolist_dhx_skyblue div.dhxcombo_option div.dhxcombo_option_text {
	position: relative;
	padding: 0px 4px;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_dhx_skyblue div.dhxcombo_option div.dhxcombo_option_text.dhxcombo_option_text_chbx {
	position: relative;
	padding: 0px 4px 0px 4px;
	margin-left: 20px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	white-space: nowrap;
}
div.dhxcombolist_dhx_skyblue div.dhxcombo_option div.dhxcombo_checkbox {
	position: absolute;
	left: 1px;
	top: 1px;
	width: 20px;
	height: 20px;
	background-image: url("imgs/dhxcombo_skyblue/dhxcombo_chbx.gif");
	background-repeat: no-repeat;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow: hidden;
	cursor: default;
}
div.dhxcombolist_dhx_skyblue div.dhxcombo_option div.dhxcombo_checkbox.dhxcombo_chbx_0 {
	background-position: 0px 0px;
}
div.dhxcombolist_dhx_skyblue .dhxcombo_option div.dhxcombo_checkbox.dhxcombo_chbx_1 {
	background-position: -18px 0px;
}
div.dhxcombolist_dhx_skyblue div.dhxcombo_option div.dhxcombo_option_text.dhxcombo_option_text_image {
	position: relative;
	padding: 0px 4px 0px 4px;
	margin-left: 20px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	white-space: nowrap;
}
div.dhxcombo_dhx_skyblue div.dhxcombo_top_image div.dhxcombo_image,
div.dhxcombolist_dhx_skyblue div.dhxcombo_option div.dhxcombo_image {
	position: absolute;
	left: 3px;
	top: 1px;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow: hidden;
	cursor: default;
}
div.dhxcombo_dhx_skyblue.dhxcombo_disabled {
	border: 1px solid #cccccc;
	background-color: #fafafa;
}
div.dhxcombo_dhx_skyblue.dhxcombo_disabled input.dhxcombo_input {
	color: #b2b2b2;
	background-color: #fafafa;
}
div.dhxcombo_dhx_skyblue.dhxcombo_disabled div.dhxcombo_select_button {
	border-color: #cccccc;
	background-color: #fefefe;
	background: linear-gradient(#fefefe,#f4f4f4);
	background: -webkit-linear-gradient(#fefefe,#f4f4f4);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#fefefe,endColorStr=#f4f4f4) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	cursor: default;
}
div.dhxcombo_dhx_skyblue.dhxcombo_disabled div.dhxcombo_select_button div.dhxcombo_select_img {
	background-image: url("imgs/dhxcombo_skyblue/dhxcombo_arrow_down_dis.gif");
}
.dhxgrid_combo_icon {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
}
div.dhxcombo_in_grid_parent {
	position: relative;
	height: 100%;
	top: 0px;
}
div.dhxcombo_in_grid_parent div.dhxcombo_dhx_skyblue {
	position: absolute;
	top: 0px;
	left: -4px;
	*top: -2px;
	*height: 23px;
}
div.dhxcombo_in_grid_parent input.dhxcombo_input {
	border-left: 2px solid white;
	font-family: Arial;
	font-size: 12px;
	*margin-top: -3px;
	*height: 18px;
	*line-height: 17px;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_hdr {
	border-bottom-width: 0px;
	height: 27px;
	line-height: 27px;
	box-shadow: none;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-color: #e2efff;
	background: linear-gradient(#e2efff,#d3e7ff);
	background: -webkit-linear-gradient(#e2efff,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e2efff,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_hdr div.dhxcombo_hdrtext {
	position: relative;
	padding: 0px;
	white-space: nowrap;
	cursor: default;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell,
div.dhxcombolist_dhx_skyblue.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell_first {
	float: left;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	z-index: 2;
	border-left: 1px solid #a4bed4;
	padding: 0px;
	margin: 0px;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell_first,
div.dhxcombolist_dhx_skyblue.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell:first-child {
	border-left-width: 0px;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option {
	height: 24px;
	line-height: 24px;
	margin-top: -1px;
	z-index: 0;
	border-top: 1px solid #d3e7ff;
	border-bottom: 1px solid #d3e7ff;
	background-color: white;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option:last-child {
	border-bottom-color: white;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_option_text {
	padding: 0px;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell,
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell_first {
	float: left;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	border-left: 1px solid #d3e7ff;
	padding: 0px;
	margin: 0px;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell_first,
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell:first-child {
	border-left-width: 0px;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option.dhxcombo_option_selected {
	z-index: 1;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option.dhxcombo_option_selected div.dhxcombo_cell {
	border-left-color: #b5deff;
}
div.dhxcombolist_dhx_skyblue.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell_text,
div.dhxcombolist_dhx_skyblue.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell_text {
	position: relative;
	margin: 0px 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: auto;
	min-height: 32px;
}
.dhxcombo_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: material
	include extra file: skins/material.less
*/

@keyframes dhx_loader_rotate {
	100% {
		transform: rotate(360deg);
	}
}
@keyframes dhx_loader_dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}
div.dhxcombo_material {
	position: relative;
	border-bottom: 1px solid #dfdfdf;
	padding-bottom: 1px;
	overflow: hidden;
	vertical-align: middle;
	background-color: white;
	font-size: 1px;
	height: 29px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
div.dhxcombo_material.dhxcombo_actv {
	padding-bottom: 0px;
	border-bottom: 2px solid #3399cc;
}
div.dhxcombo_material input.dhxcombo_input {
	position: absolute;
	left: 4px;
	top: 0px;
	*top: 1px;
	height: 29px;
	line-height: 28px;
	*height: 27px;
	*line-height: 26px;
	border: 0px solid white;
	outline: 0px solid white;
	padding: 0px;
	margin: 0px 0px 0px 2px;
	background-color: white;
	vertical-align: top;
	font-size: 14px;
	font-family: Roboto, Arial, Helvetica;
	color: #404040;
}
div.dhxcombo_material input.dhxcombo_input::-ms-clear {
	display: none;
}
div.dhxcombo_material div.dhxcombo_select_button {
	position: absolute;
	width: 21px;
	height: 22px;
	top: 4px;
	right: 2px;
	font-size: 1px;
	cursor: pointer;
	overflow: hidden;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombo_material div.dhxcombo_select_button div.dhxcombo_select_img {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url("imgs/dhxcombo_material/dhxcombo_arrow_down.gif");
	background-repeat: no-repeat;
	background-position: center center;
}
div.dhxcombo_material div.dhxcombo_top_image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 29px;
	height: 29px;
	overflow: hidden;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombo_material div.dhxcombo_top_image div.dhxcombo_image {
	position: absolute;
	top: 6px;
	left: 5px;
	width: 18px;
	height: 18px;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_material {
	position: absolute;
	border: 1px solid #dfdfdf;
	background-color: #fafafa;
	overflow-x: none;
	overflow-y: auto;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	box-shadow: 0 0 10px rgba(127,127,127,0.2);
}
div.dhxcombolist_material div.dhxcombo_option {
	position: relative;
	font: inherit;
	height: 32px;
	line-height: 32px;
	vertical-align: middle;
	overflow: hidden;
	cursor: default;
	font-size: 14px;
	font-family: Roboto, Arial, Helvetica;
	color: #404040;
}
div.dhxcombolist_material div.dhxcombo_option.dhxcombo_option_selected {
	background-color: #ebebeb;
}
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_option_text {
	position: relative;
	padding: 0px 4px;
	font: inherit;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_option_text.dhxcombo_option_text_chbx {
	position: relative;
	padding: 0px 4px;
	margin-left: 26px;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_option_text.dhxcombo_option_text_image {
	position: relative;
	padding: 0px 4px 0px 4px;
	margin-left: 26px;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_image,
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_checkbox {
	position: absolute;
	width: 18px;
	margin: 0px 5px;
	height: 32px;
	line-height: 32px;
	background-image: url("imgs/dhxcombo_material/dhxcombo_chbx.gif");
	background-repeat: no-repeat;
	overflow: hidden;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_image.dhxcombo_chbx_1,
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_checkbox.dhxcombo_chbx_1 {
	background-position: 0px 7px;
}
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_image.dhxcombo_chbx_0,
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_checkbox.dhxcombo_chbx_0 {
	background-position: -18px 7px;
}
div.dhxcombolist_material div.dhxcombo_option div.dhxcombo_image {
	top: 7px;
	margin: 0px 0px 0px 6px;
	width: 18px;
	height: 18px;
}
div.dhxcombo_material.dhxcombo_disabled input.dhxcombo_input {
	color: #a6a6a6;
}
div.dhxcombo_material.dhxcombo_disabled div.dhxcombo_select_button div.dhxcombo_select_img {
	background-image: url("imgs/dhxcombo_material/dhxcombo_arrow_down_dis.gif");
	cursor: default;
}
.dhxgrid_combo_icon {
	position: absolute;
	z-index: 1;
	top: 0px;
	right: 0px;
}
div.dhxcombo_in_grid_parent {
	position: relative;
	height: 100%;
	top: 0px;
}
div.dhxcombo_in_grid_parent div.dhxcombo_material {
	position: absolute;
	top: 0px;
	left: -1px;
}
div.dhxcombolist_material.dhxcombolist_hdr {
	border-bottom-width: 0px;
	height: 36px;
	line-height: 36px;
	box-shadow: none;
}
div.dhxcombolist_material.dhxcombolist_hdr div.dhxcombo_hdrtext {
	position: relative;
	padding: 0px;
	white-space: nowrap;
	cursor: default;
	font-family: Roboto, Arial, Helvetica;
	font-size: 14px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhxcombolist_material.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell,
div.dhxcombolist_material.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell_first {
	float: left;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	z-index: 2;
	border-left: 1px solid #dfdfdf;
	padding: 0px;
	margin: 0px;
}
div.dhxcombolist_material.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell_first,
div.dhxcombolist_material.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell:first-child {
	border-left-width: 0px;
}
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option {
	height: 32px;
	line-height: 32px;
	margin-top: -1px;
	z-index: 0;
	border-top: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	background-color: white;
}
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_option_text {
	padding: 0px;
}
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell,
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell_first {
	float: left;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	border-left: 1px solid #dfdfdf;
	padding: 0px;
	margin: 0px;
}
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell_first,
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell:first-child {
	border-left-width: 0px;
}
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option.dhxcombo_option_selected {
	z-index: 1;
	background-color: #ebebeb;
	border-top-color: #dfdfdf;
	border-bottom-color: #dfdfdf;
}
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option.dhxcombo_option_selected div.dhxcombo_cell {
	border-left-color: #dfdfdf;
}
div.dhxcombolist_material.dhxcombolist_multicolumn div.dhxcombo_option div.dhxcombo_cell_text,
div.dhxcombolist_material.dhxcombolist_hdr div.dhxcombo_hdrtext div.dhxcombo_hdrcell_text {
	position: relative;
	margin: 0px 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: auto;
	min-height: 32px;
}
.dhxcombo_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 40px;
	height: 10px;
	overflow: hidden;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_terrace
	include extra file: skins/dhx_terrace.less
*/

.dhxtabbar_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 30px;
	height: 10px;
	overflow: hidden;
}
.dhxtabbar_fullscreen {
	width: 100%;
	height: 100%;
	margin: 0px;
	overflow: hidden;
}
.dhxtabbar_base_dhx_terrace {
	position: relative;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_cont {
	position: absolute;
	*overflow: hidden;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs {
	position: absolute;
	top: 0px;
	bottom: auto;
	height: 33px;
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
	overflow: hidden;
	white-space: nowrap;
	background-color: #ffffff;
	z-index: 2;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base {
	position: absolute;
	top: 0px;
	height: 34px;
	overflow: hidden;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_cont_left,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_cont_right {
	position: absolute;
	width: 5000px;
	height: 34px;
	margin: 0px 1px;
	top: -1px;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	-o-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_line {
	position: absolute;
	width: 100%;
	left: 0px;
	bottom: 0px;
	top: none;
	height: 1px;
	overflow: hidden;
	background-color: #cccccc;
	border: none;
	z-index: 2;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 17px;
	height: 32px;
	background-color: #ffffff;
	border-bottom: 1px solid #cccccc;
	z-index: 4;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: pointer;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left.dhxtabbar_tabs_ar_hidden {
	display: none;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left div.dhxtabbar_arrow_img {
	position: relative;
	height: 100%;
	border-right: 1px solid #cccccc;
	background-image: url("imgs/dhxtabbar_terrace/dhxtabbar_arrows.gif");
	background-position: 1px 14px;
	background-repeat: no-repeat;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 17px;
	height: 32px;
	background-color: #ffffff;
	border-bottom: 1px solid #cccccc;
	z-index: 4;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: pointer;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right.dhxtabbar_tabs_ar_hidden {
	display: none;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right div.dhxtabbar_arrow_img {
	position: relative;
	height: 100%;
	border-left: 1px solid #cccccc;
	background-image: url("imgs/dhxtabbar_terrace/dhxtabbar_arrows.gif");
	background-position: -13px 14px;
	background-repeat: no-repeat;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab {
	position: relative;
	height: 34px;
	font-family: Arial, Helvetica;
	font-size: 13px;
	color: #333333;
	z-index: 1;
	cursor: pointer;
	background-color: #f5f5f5;
	margin-left: -1px;
	margin-right: 0px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	-o-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab:hover div.dhxtabbar_tab_text {
	color: #262626;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab:hover div.dhxtabbar_tab_close {
	background-color: #e8e8e8;
	border-color: #c7c7c7;
	background-position: -15px 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_dis {
	color: #bbbbbb;
	background-color: #fafafa;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv_dis {
	background-color: #ffffff;
	z-index: 3;
	font-weight: bold;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_hidden {
	border: none;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab div.dhxtabbar_tab_text {
	position: relative;
	height: 33px;
	line-height: 33px;
	vertical-align: middle;
	top: 0px;
	bottom: none;
	text-align: center;
	padding: 0px 15px;
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
	border-bottom: 0px solid #ffffff;
	overflow: hidden;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab div.dhxtabbar_tab_text.dhxtabbar_tab_text_close {
	padding-right: 20px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv div.dhxtabbar_tab_text {
	color: #333333;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_dis div.dhxtabbar_tab_text,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv_dis div.dhxtabbar_tab_text {
	color: #bbbbbb;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_dis div.dhxtabbar_tab_close,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_dis:hover div.dhxtabbar_tab_close,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv_dis div.dhxtabbar_tab_close,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv_dis:hover div.dhxtabbar_tab_close {
	background-color: #e8e8e8;
	border-color: #c7c7c7;
	background-position: -30px 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab div.dhxtabbar_tab_close {
	position: absolute;
	top: 9px;
	right: 9px;
	width: 15px;
	height: 15px;
	border: 1px solid #cccccc;
	background-color: #f0f0f0;
	border-radius: 3px;
	background-image: url("imgs/dhxtabbar_terrace/dhxtabbar_button_close.gif");
	background-position: 0px 0px;
	background-repeat: no-repeat;
	z-index: 1;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv div.dhxtabbar_tab_close,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv:hover div.dhxtabbar_tab_close {
	background-color: #ffffff;
	border-color: #cccccc;
	background-position: 0px 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_cont_left div.dhxtabbar_tab {
	float: left;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_cont_right div.dhxtabbar_tab {
	float: right;
	margin-right: -1px;
	margin-left: 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs {
	top: auto;
	bottom: 0px;
	border-bottom: 1px solid #cccccc;
	border-top: 0px solid #ffffff;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_cont_left,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_cont_right {
	top: 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_line {
	top: 0px;
	bottom: none;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tab {
	margin-top: 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv_dis {
	margin-top: 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tab div.dhxtabbar_tab_text {
	border-bottom: 1px solid #cccccc;
	border-top: 0px solid white;
	top: none;
	bottom: 0px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right {
	top: 0px;
	bottom: none;
	border-top: 1px solid #cccccc;
	border-bottom: 0px solid #ffffff;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhx_cell_tabbar div.dhx_cell_cont_tabbar {
	border-top: 1px solid #cccccc;
	border-bottom: 0px solid white;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhx_cell_tabbar div.dhx_cell_menu_def {
	border-top: 1px solid #cccccc;
	border-bottom: 0px solid white;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhx_cell_tabbar div.dhx_cell_toolbar_def {
	border-top: 1px solid #cccccc;
	border-bottom: 0px solid white;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs_bottom div.dhx_cell_tabbar div.dhx_cell_statusbar_def div.dhx_cell_statusbar_text {
	border-top: 1px solid #cccccc;
	border-bottom: 0px solid white;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar {
	position: absolute;
	background-color: #ffffff;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	cursor: default;
	z-index: 0;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_cover {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	font-size: 1px;
	background-color: white;
	overflow: hidden;
	z-index: 1;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_cont_tabbar {
	position: absolute;
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
	border-top: 0px solid white;
	overflow: hidden;
	z-index: 0;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_cont_tabbar.dhx_cell_cont_no_borders {
	border: 0px solid white !important;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_menu_def {
	position: relative;
	overflow: hidden;
	padding: 6px;
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_menu_no_borders {
	position: relative;
	overflow: hidden;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_toolbar_def {
	position: relative;
	padding: 6px;
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhtmlxribbon_dhx_terrace.dhxrb_without_tabbar {
	border-top: 0px solid white;
	width: auto;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhxrb_with_tabbar.dhxtabbar_base_dhx_terrace {
	margin-top: -1px;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_statusbar_def {
	position: absolute;
	bottom: 0px;
	width: 100%;
	font-family: Arial, Helvetica;
	font-size: 13px;
	color: #333333;
	background-color: #f5f5f5;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_statusbar_def div.dhx_cell_statusbar_text {
	position: relative;
	padding: 0px 4px;
	height: 28px;
	line-height: 27px;
	border-bottom: 1px solid #cccccc;
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	white-space: nowrap;
	overflow: hidden;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_progress_bar {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0.75;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=75);
	z-index: 1;
}
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_progress_img {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-image: url("imgs/dhxtabbar_terrace/dhxtabbar_cell_progress.gif");
	background-position: center center;
	background-repeat: no-repeat;
	z-index: 2;
}
span.dhxtabbar_tabs_text_test_dhx_terrace {
	position: absolute;
	visibility: hidden;
	right: 0px;
	top: 0px;
	font-weight: bold;
	padding: 0px 4px;
	font-family: Arial, Helvetica;
	font-size: 13px;
	color: #333333;
}

/* tabs area */
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs {
	border-width: 0px;
	border-color: #91a7b4;
	height: 23px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base {
	height: 24px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_cont_left,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_cont_right {
	height: 24px;
}
/* tab */
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab {
	margin-top: 4px;
	background-color: #f4f3ee;
	margin-left: 1px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab div.dhxtabbar_tab_text {
	line-height: 19px;
	border-color: #91a7b4;
	font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
	font-size: 8pt;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	/* additional */
	padding: 0 0px;
}
span.dhxtabbar_tabs_text_test_dhx_terrace {
	padding: 0px 0px;
	font-family: Arial, Helvetica;
	font-size: 8pt;
}
/* active tab */
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv:hover {
	border-top: 1px solid #e68b2c;
	margin-top: 2px;
	background-color: #f4f4f4;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv_dis {
	font-weight: normal;/*  bold; */
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv div.dhxtabbar_tab_text,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab.dhxtabbar_tab_actv:hover div.dhxtabbar_tab_text {
	border-top: 2px solid #ffc73c;
	line-height: 16px;
	font-weight: bold;
	padding: 0 3px;
}
/* hover tab */
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab:hover {
	border-top: 1px solid #e68b2c;
	margin-top: 3px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tab:hover div.dhxtabbar_tab_text {
	border-top: 2px solid #ffc73c;
	line-height: 16px;
}
/* tabs' bottom line */
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_base div.dhxtabbar_tabs_line {
	background-color: #91a7b4;
}
/* cell */
.dhxtabbar_base_dhx_terrace div.dhx_cell_tabbar div.dhx_cell_cont_tabbar {
	border-color: #91a7b4;
	background-color: #f4f4f4;
}
/* arrows */
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left div.dhxtabbar_arrow_img,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right div.dhxtabbar_arrow_img {
	border-width: 0px 0px 1px 0px;
	border-bottom: 1px solid #91a7b4;
	margin-top: 7px;
	height: 15px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left div.dhxtabbar_arrow_img {
	background-position: 2px 4px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right div.dhxtabbar_arrow_img {
	background-position: -12px 4px;
}
/* hidden arrows */
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left.dhxtabbar_tabs_ar_hidden,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right.dhxtabbar_tabs_ar_hidden {
	display: block;
	width: 9px;
}
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_left.dhxtabbar_tabs_ar_hidden div.dhxtabbar_arrow_img,
.dhxtabbar_base_dhx_terrace div.dhxtabbar_tabs div.dhxtabbar_tabs_ar_right.dhxtabbar_tabs_ar_hidden div.dhxtabbar_arrow_img {
	background: none;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhxlayout_base_dhx_skyblue {
	background-color: #ebebeb;
	position: relative;
	cursor: default;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont {
	position: absolute;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout {
	position: absolute;
	background-color: #ffffff;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	cursor: default;
	z-index: 0;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_cover {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	font-size: 1px;
	background-color: white;
	overflow: hidden;
	z-index: 1;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_cont_layout {
	position: absolute;
	border-left: 1px solid #a4bed4;
	border-right: 1px solid #a4bed4;
	border-bottom: 1px solid #a4bed4;
	border-top: 0px solid #ffffff;
	overflow: hidden;
	z-index: 0;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_cont_layout.dhx_cell_cont_no_borders {
	border-width: 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_cont_layout,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_cont_layout {
	display: none;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr {
	position: relative;
	height: 27px;
	line-height: 26px;
	overflow: hidden;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: #34404b;
	font-weight: bold;
	border: 1px solid #a4bed4;
	background-color: #e2efff;
	background: linear-gradient(#e2efff,#d3e7ff);
	background: -webkit-linear-gradient(#e2efff,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e2efff,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	cursor: default;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr.dhx_cell_hdr_hidden {
	height: 0px;
	line-height: 0px;
	border-bottom-width: 0px;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr.dhx_cell_hdr_hidden_no_borders {
	height: 0px;
	line-height: 0px;
	border-width: 0px;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhx_cell_hdr_text {
	position: relative;
	margin: 0px 26px 0px 5px;
	overflow: hidden;
	white-space: nowrap;
	cursor: default;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow {
	position: absolute;
	right: 4px;
	top: 6px;
	width: 16px;
	height: 16px;
	background-image: url('imgs/dhxlayout_skyblue/dhxlayout_cell_btns.gif');
	background-repeat: no-repeat;
	cursor: pointer;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_va {
	background-position: -16px 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_vb {
	background-position: -48px 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_ha {
	background-position: -32px 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_hb {
	background-position: 0px 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr {
	height: 18px;
	line-height: 17px;
	color: #3a4854;
	font-weight: normal;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_ha {
	background-position: 0px -5px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_hb {
	background-position: -32px -4px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr {
	width: 19px;
	background: -moz-linear-gradient(left,#e2efff,#d3e7ff);
	background: -webkit-linear-gradient(left,#e2efff,#d3e7ff);
	background: -o-linear-gradient(left,#e2efff,#d3e7ff);
	background: -ms-linear-gradient(left,#e2efff,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=1,startColorStr=#e2efff,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text {
	position: absolute;
	left: 3px;
	bottom: 0px;
	transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform-origin: left center;
	color: #3a4854;
	font-weight: normal;
	text-overflow: ellipsis;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie6 {
	left: -5px;
	line-height: 17px;
	padding-bottom: 10px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie6 span {
	float: left;
	filter: progid:DXImageTransform.Microsoft.Matrix(M11='6.123031769111886e-17', M12='1', M21='-1', M22='6.123031769111886e-17', sizingMethod='auto expand');
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie7 {
	left: -5px;
	line-height: 17px;
	padding-bottom: 10px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie7 span {
	float: left;
	filter: progid:DXImageTransform.Microsoft.Matrix(M11='6.123031769111886e-17', M12='1', M21='-1', M22='6.123031769111886e-17', sizingMethod='auto expand');
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie8 {
	left: -5px;
	line-height: 17px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie8 span {
	float: left;
	filter: progid:DXImageTransform.Microsoft.Matrix(M11='6.123031769111886e-17', M12='1', M21='-1', M22='6.123031769111886e-17', sizingMethod='auto expand');
	margin-bottom: 95%;
	padding-right: 8px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie9 {
	left: 0px;
	line-height: 17px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_ie9 span {
	float: left;
	filter: none;
	margin-bottom: 95%;
	padding-right: 8px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhx_cell_hdr_text.dhx_cell_hdr_text_chrome {
	left: 5px;
	-webkit-transform: rotate(270deg);
	-webkit-transform-origin: left center;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_va {
	background-position: -45px 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_hdr div.dhxlayout_arrow.dhxlayout_arrow_vb {
	background-position: -13px 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep {
	position: absolute;
	background-color: #ebebeb;
	font-size: 1px;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-ms-touch-action: none;
	touch-action: none;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep.dhxlayout_sep_resize_v {
	cursor: w-resize;
	background-image: url('imgs/dhxlayout_skyblue/dhxlayout_sep_v.gif');
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep.dhxlayout_sep_resize_h {
	cursor: n-resize;
	background-image: url('imgs/dhxlayout_skyblue/dhxlayout_sep_h.gif');
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_sep.dhxlayout_sep_resize_actv {
	background-color: #dedede;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_resize_area {
	position: absolute;
	background-color: #a4bed4;
	opacity: 0.15;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=15);
	z-index: 1;
	border: 2px dashed black;
	cursor: inherit;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhxlayout_resize_sep {
	position: absolute;
	background-color: #a4bed4;
	overflow: hidden;
	display: block;
	opacity: 0.5;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
	z-index: 2;
	cursor: inherit;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_menu_def {
	position: relative;
	overflow: hidden;
	border-width: 0px 1px 1px 1px;
	border-color: #a4bed4;
	border-style: solid;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_menu_def div.dhtmlxMenu_dhx_skyblue_Middle {
	padding: 0px 2px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_menu_no_borders {
	position: relative;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_menu_no_borders div.dhtmlxMenu_dhx_skyblue_Middle {
	padding: 0px 2px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_toolbar_def {
	position: relative;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_toolbar_def div.dhx_toolbar_dhx_skyblue {
	border-top-width: 0px;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_ribbon_def div.dhxrb_with_tabbar.dhxtabbar_base_dhx_skyblue {
	margin-top: -1px;
	width: auto;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_ribbon_def div.dhtmlxribbon_dhx_skyblue.dhxrb_without_tabbar {
	border-top-width: 0px;
	width: auto;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_statusbar_def {
	position: absolute;
	bottom: 0px;
	width: 100%;
	background-color: #ddecff;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_statusbar_def div.dhx_cell_statusbar_text {
	position: relative;
	padding: 0px 4px;
	height: 21px;
	line-height: 21px;
	border-bottom: 1px solid #a4bed4;
	border-left: 1px solid #a4bed4;
	border-right: 1px solid #a4bed4;
	white-space: nowrap;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_progress_bar {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: white;
	z-index: 5;
	opacity: 0.75;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=75);
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_progress_img {
	position: absolute;
	left: 0px;
	top: 0px;
	border: 1px solid #a4bed4;
	background-position: center 55%;
	background-image: url('imgs/dhxlayout_skyblue/dhxlayout_cell_progress.gif');
	background-repeat: no-repeat;
	cursor: progress;
	z-index: 6;
}
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_h div.dhx_cell_statusbar_def,
.dhxlayout_base_dhx_skyblue .dhxlayout_cont div.dhx_cell_layout.dhxlayout_collapsed_v div.dhx_cell_statusbar_def {
	display: none;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_progress {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: white;
	z-index: 3;
	opacity: 0.55;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=55);
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_progress_img {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-image: url('imgs/dhxlayout_skyblue/dhxlayout_cell_progress.gif');
	background-position: center center;
	background-repeat: no-repeat;
	z-index: 4;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_hdr {
	position: relative;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_ftr {
	position: absolute;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_menu {
	position: relative;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_toolbar {
	position: relative;
	background-color: #ebebeb;
	padding-bottom: 4px;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_ribbon {
	padding-bottom: 4px;
	position: relative;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_ribbon div.dhtmlxribbon_dhx_skyblue.dhxrb_without_tabbar {
	width: auto;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_statusbar {
	position: absolute;
	bottom: 0px;
	width: 100%;
	background-color: #ebebeb;
	overflow: hidden;
}
.dhxlayout_base_dhx_skyblue div.dhxcelltop_statusbar div.dhxcont_statusbar {
	position: relative;
	margin-top: 4px;
	border: 1px solid #a4bed4;
	background-color: #ddecff;
	padding: 7px 6px;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
}
body.dhxlayout_resize_v * {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: w-resize !important;
}
body.dhxlayout_resize_h * {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: n-resize !important;
}
div.dhxlayout_sep_sw_dhx_skyblue {
	position: absolute;
	left: 0px;
	top: -100px;
	width: 5px;
	height: 1px;
	font-size: 1px;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
}
.dhxlayout_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhxwins_vp_dhx_skyblue {
	overflow: hidden;
	position: relative;
	cursor: default;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active {
	position: absolute;
	overflow: hidden;
	border: 1px solid #a4bed4;
	box-shadow: 0 0 3px #cecece;
	border-radius: 3px;
	background: white;
	cursor: inherit;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive {
	position: absolute;
	overflow: hidden;
	border: 1px solid #c1d1de;
	box-shadow: 0 0 3px #dedede;
	border-radius: 3px;
	background: white;
	cursor: inherit;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_brd {
	position: absolute;
	border-left: 5px solid #d3e6fe;
	border-right: 5px solid #d3e6fe;
	border-bottom: 5px solid #d3e6fe;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	background: white;
	z-index: 0;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_brd.dhxwin_hdr_hidden {
	border-top: 5px solid #d3e6fe;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhxwin_brd {
	opacity: 0.8;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_fr_cover {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: white;
	z-index: 1;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_fr_cover .dhxwin_fr_cover_inner {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhxwin_fr_cover {
	display: none;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhxwin_fr_cover {
	z-index: 4;
	opacity: 0.5;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhxwin_fr_cover.dhxwin_fr_cover_dnd,
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhxwin_fr_cover.dhxwin_fr_cover_resize {
	display: block;
}
.dhxwins_vp_dhx_skyblue iframe.dhxwin_main_fr_cover {
	position: absolute;
	background-color: white;
	border-radius: 2px;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr {
	position: relative;
	height: 30px;
	line-height: 30px;
	margin: 0px;
	padding: 0px;
	font-weight: bold;
	border-top: 1px solid white;
	border-left: 1px solid white;
	border-right: 1px solid white;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	background-color: #e5f0fd;
	background: linear-gradient(#e5f0fd,#d3e6fe);
	background: -webkit-linear-gradient(#e5f0fd,#d3e6fe);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e5f0fd,endColorStr=#d3e6fe) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: #34404b;
	cursor: inherit;
	overflow: hidden;
	z-index: 3;
	-ms-touch-action: none;
	touch-action: none;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhxwin_hdr {
	color: #777;
	opacity: 0.8;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e5f0fd,endColorStr=#d3e6fe);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr.dhxwin_hdr_parked {
	border-bottom: 1px solid white;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr.dhxwin_hdr_hidden {
	visibility: hidden;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_icon {
	position: absolute;
	left: 7px;
	top: 7px;
	width: 18px;
	height: 18px;
	cursor: default;
	overflow: hidden;
	background-image: url("imgs/dhxwins_skyblue/dhxwins_icon.gif");
	background-position: center center;
	background-repeat: no-repeat;
	cursor: inherit;
	z-index: 2;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhxwin_hdr div.dhxwin_icon {
	opacity: 0.6;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=60);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_text {
	position: relative;
	padding: 0px;
	margin: 0px;
	overflow: hidden;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: inherit;
	z-index: 1;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_text div.dhxwin_text_inside {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns {
	position: absolute;
	right: 7px;
	top: 6px;
	height: 18px;
	cursor: default;
	overflow: hidden;
	cursor: inherit;
	z-index: 2;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhxwin_hdr div.dhxwin_btns {
	opacity: 0.5;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button,
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis {
	position: relative;
	float: left;
	width: 15px;
	height: 15px;
	margin-left: 1px;
	margin-top: 2px;
	border-radius: 2px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	background-image: url("imgs/dhxwins_skyblue/dhxwins_buttons.gif");
	background-repeat: no-repeat;
	background-position: 0px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button:hover {
	background-color: white;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_close {
	background-position: -15px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_close_dis {
	background-position: -15px -15px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_minmax {
	background-position: -30px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_minmax_dis {
	background-position: -30px -15px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_minmaxed {
	background-position: -45px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_minmaxed_dis {
	background-position: -45px -15px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_park {
	background-position: -60px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_park_dis {
	background-position: -60px -15px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_stick {
	background-position: -75px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_stick_dis {
	background-position: -75px -15px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_sticked {
	background-position: -90px 0px;
	background-color: #a4bed4 !important;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_sticked_dis {
	background-position: -90px -15px;
	background-color: #f0f0f0 !important;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_help {
	background-position: -105px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_help_dis {
	background-position: -105px -15px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button.dhxwin_button_dock {
	background-position: -120px 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_hdr div.dhxwin_btns div.dhxwin_button_dis.dhxwin_button_dock_dis {
	background-position: -120px -15px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active.dhxwin_dnd_touch,
.dhxwins_vp_dhx_skyblue div.dhxwin_active.dhxwin_dnd {
	box-shadow: 0 0 3px #cececf;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active.dhxwin_dnd_touch {
	border-color: #6c94b4;
	box-shadow: 0 0 14px #a4b9cb;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
}
.dhxwins_vp_dhx_skyblue.dhxwins_vp_dnd {
	cursor: move !important;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_resize {
	position: absolute;
	background-color: #d3e6fe;
	border-radius: 3px;
	opacity: 0.5;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
}
.dhxwins_vp_dhx_skyblue iframe.dhxwin_resize_fr_cover {
	position: absolute;
	background-color: white;
	border-radius: 3px;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.dhxwins_vp_dhx_skyblue div.dhxwins_mcover {
	position: absolute;
	background-color: #e5f0fd;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	opacity: 0.53;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=53);
}
.dhxwins_vp_dhx_skyblue iframe.dhxwins_mcover {
	position: absolute;
	background-color: white;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins {
	position: absolute;
	background-color: #ffffff;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	cursor: default;
	visibility: visible;
	z-index: 1;
	opacity: 1;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins.dhxwin_parked,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins.dhxwin_parked {
	visibility: hidden;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_cover,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_cover {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	font-size: 1px;
	background-color: white;
	overflow: hidden;
	z-index: 1;
	opacity: 0;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_cont_wins,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_cont_wins {
	position: absolute;
	border: 1px solid #ffffff;
	background-color: #ffffff;
	overflow: hidden;
	z-index: 0;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_cont_wins.dhx_cell_cont_no_borders,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_cont_wins.dhx_cell_cont_no_borders {
	border: 0px solid #ffffff !important;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_menu_def,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_menu_def {
	position: relative;
	overflow: hidden;
	border-bottom: 1px solid #d3e6fe;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_menu_def div.dhtmlxMenu_dhx_skyblue_Middle,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_menu_def div.dhtmlxMenu_dhx_skyblue_Middle {
	padding: 0px 2px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_toolbar_def,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_toolbar_def {
	position: relative;
	overflow: hidden;
	padding: 1px 1px 0px 1px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_toolbar_def div.dhx_toolbar_dhx_skyblue,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_toolbar_def div.dhx_toolbar_dhx_skyblue {
	position: relative;
	border-top-width: 1px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_toolbar_no_borders div.dhx_toolbar_dhx_skyblue,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_toolbar_no_borders div.dhx_toolbar_dhx_skyblue {
	position: relative;
	border-top-width: 1px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_ribbon_def,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_ribbon_def {
	padding: 1px 1px 0px 1px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhtmlxribbon_dhx_skyblue.dhxrb_without_tabbar,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhtmlxribbon_dhx_skyblue.dhxrb_without_tabbar {
	width: auto;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_statusbar_def,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_statusbar_def {
	position: absolute;
	bottom: 0px;
	width: 100%;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	background-color: #d3e6fe;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_statusbar_def div.dhx_cell_statusbar_text,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_statusbar_def div.dhx_cell_statusbar_text {
	position: relative;
	padding: 0px 4px;
	height: 21px;
	line-height: 21px;
	white-space: nowrap;
	overflow: hidden;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_cont_wins.dhx_cell_statusbar_attached,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_cont_wins.dhx_cell_statusbar_attached {
	border-radius: 0px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_progress_bar,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_progress_bar {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	opacity: 0.75;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=75);
	z-index: 1;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_progress_img,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_progress_img {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-image: url("imgs/dhxwins_skyblue/dhxwins_progress.gif");
	background-position: center center;
	background-repeat: no-repeat;
	z-index: 2;
}
.dhxwins_vp_auto {
	overflow: auto !important;
}
.dhxwins_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}
div.dhxwins_vp_dhx_skyblue.dhxwins_vp_fs {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhxtoolbar_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}
.dhx_toolbar_dhx_skyblue {
	border: 1px solid #a4bed4;
	background-color: #e2efff;
	background: linear-gradient(#e2efff,#d3e7ff);
	background: -webkit-linear-gradient(#e2efff,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e2efff,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	position: relative;
	padding: 0px 5px;
	height: 30px;
	line-height: normal;
	cursor: default;
	overflow: hidden;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_btn i {
	font-size: 20px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_btn i {
	font-size: 26px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_btn i {
	font-size: 38px;
}
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_float_left {
	float: left;
}
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_float_right {
	float: right;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn,
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw {
	position: relative;
	float: left;
	margin-top: 2px;
	padding: 2px 3px;
	*padding-bottom: 4px;
	margin-right: 1px;
	line-height: normal;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: default;
	height: 22px;
	*height: 20px;
	overflow: hidden;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_dis,
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw.dhxtoolbar_btn_dis {
	color: #999999;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_over,
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw.dhxtoolbar_btn_over {
	border: 1px solid #a4bed4;
	border-radius: 2px;
	padding: 1px 2px;
	*padding-bottom: 3px;
	background-color: #f1f7ff;
	background: linear-gradient(#f1f7ff,#e2efff);
	background: -webkit-linear-gradient(#f1f7ff,#e2efff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#f1f7ff,endColorStr=#e2efff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_pres,
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw.dhxtoolbar_btn_pres {
	border: 1px solid #a4bed4;
	border-radius: 2px;
	padding: 1px 2px;
	*padding-bottom: 3px;
	background-color: #d2e7fe;
	background: linear-gradient(#d2e7fe,#d3e7ff);
	background: -webkit-linear-gradient(#d2e7fe,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#d2e7fe,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	box-shadow: 0 0 5px rgba(127,127,127,0.15) inset;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_pres_dis {
	color: #999999;
	border: 1px solid #c9c9c9;
	border-radius: 2px;
	padding: 1px 2px;
	*padding-bottom: 3px;
	background-color: #d2e7fe;
	background: linear-gradient(#d2e7fe,#d3e7ff);
	background: -webkit-linear-gradient(#d2e7fe,#d3e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#d2e7fe,endColorStr=#d3e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	box-shadow: 0 0 5px rgba(127,127,127,0.15) inset;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn img {
	float: left;
	margin: 2px;
	width: 18px;
	height: 18px;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn i {
	float: left;
	margin: 2px;
	width: 18px;
	height: 18px;
	line-height: 17px;
	font-size: 1.3em;
	text-align: center;
	cursor: default;
	color: inherit;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn .dhxtoolbar_input {
	position: relative;
	float: left;
	border: 1px solid #a4bed4;
	background-color: white;
	margin: 1px;
	padding: 2px 4px 3px 4px;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	line-height: normal;
	direction: ltr;
	outline: none;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn .dhxtoolbar_input:disabled {
	background-color: #e7eef8;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn div.dhxtoolbar_text,
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw div.arwimg {
	float: left;
	font: inherit;
	height: 18px;
	line-height: 17px;
	margin: 2px 4px;
	padding: 0px;
	cursor: default;
	overflow: hidden;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw {
	margin-left: -3px;
	z-index: 1;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw div.arwimg {
	margin: 2px 0px;
	padding: 0px 3px;
	background-image: url("imgs/dhxtoolbar_skyblue/dhxtoolbar_arrow.gif");
	background-position: center center;
	background-repeat: no-repeat;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_arw.dhxtoolbar_btn_dis div.arwimg {
	background-image: url("imgs/dhxtoolbar_skyblue/dhxtoolbar_arrow_dis.gif");
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_sep {
	float: left;
	border-left: 1px solid #c0d9ec;
	height: 20px;
	margin: 5px 6px 0px 5px;
	overflow: hidden;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_text {
	float: left;
	vertical-align: middle;
	margin-top: 3px;
	padding: 3px 5px;
	line-height: 17px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_sl_bg_l,
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_sl_bg_m,
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_sl_bg_r {
	float: left;
	height: 5px;
	line-height: 1px;
	margin: 8px 0px 6px 0px;
	padding: 0px;
	width: 2px;
	font-size: 1px;
	border-top: 1px solid #a4bed5;
	border-bottom: 1px solid #a4bed5;
	background-color: #e7f1ff;
	overflow: hidden;
}
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_sl_bg_l {
	margin-left: 4px;
	border-left: 1px solid #a4bed5;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_sl_bg_r {
	margin-right: 4px;
	border-right: 1px solid #a4bed5;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_sl_pen,
.dhx_toolbar_dhx_skyblue div.dhxtoolbar_sl_pen.dhxtoolbar_over {
	position: absolute;
	top: 5px;
	width: 7px;
	height: 14px;
	border: 1px solid #a4bed4;
	background-color: #e1eeff;
	background: linear-gradient(#e1eeff,#d4e7ff);
	background: -webkit-linear-gradient(#e1eeff,#d4e7ff);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e1eeff,endColorStr=#d4e7ff) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	border-radius: 1px;
	cursor: default;
	font-size: 1px;
	line-height: 1px;
	overflow: hidden;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_dis div.dhxtoolbar_sl_pen {
	border-color: #c9c9c9;
	background-color: #e5e5e5;
	background: linear-gradient(#e5e5e5,#e0e0e0);
	background: -webkit-linear-gradient(#e5e5e5,#e0e0e0);
	filter: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#e5e5e5,endColorStr=#e0e0e0) progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_dis div.dhxtoolbar_sl_bg_l,
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_dis div.dhxtoolbar_sl_bg_m,
.dhx_toolbar_dhx_skyblue div.dhx_toolbar_btn.dhxtoolbar_btn_dis div.dhxtoolbar_sl_bg_r {
	border-color: #c9c9c9;
	background-color: #e9e9e9;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 {
	height: 36px !important;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_btn,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_arw {
	height: 28px;
	*height: 26px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_btn img {
	width: 24px;
	height: 24px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_btn i {
	width: 24px;
	height: 24px;
	line-height: 23px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_btn div.dhxtoolbar_text,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_arw div.arwimg {
	height: 24px;
	line-height: 23px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_text {
	padding-top: 6px;
	padding-bottom: 6px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_sep {
	height: 26px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhxtoolbar_sl_bg_l,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhxtoolbar_sl_bg_m,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhxtoolbar_sl_bg_r {
	margin-top: 11px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhxtoolbar_sl_pen {
	top: 8px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_24 div.dhx_toolbar_btn .dhxtoolbar_input {
	margin-top: 4px;
	margin-bottom: 4px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 {
	height: 44px !important;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_btn,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_arw {
	height: 36px;
	*height: 34px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_btn img {
	width: 32px;
	height: 32px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_btn i {
	width: 32px;
	height: 32px;
	line-height: 31px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_btn div.dhxtoolbar_text,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_arw div.arwimg {
	height: 32px;
	line-height: 31px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_text {
	padding-top: 10px;
	padding-bottom: 10px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_sep {
	height: 34px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhxtoolbar_sl_bg_l,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhxtoolbar_sl_bg_m,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhxtoolbar_sl_bg_r {
	margin-top: 15px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhxtoolbar_sl_pen {
	top: 12px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_32 div.dhx_toolbar_btn .dhxtoolbar_input {
	margin-top: 8px;
	margin-bottom: 8px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 {
	height: 60px !important;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_btn,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_arw {
	height: 52px;
	*height: 50px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_btn img {
	width: 48px;
	height: 48px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_btn i {
	width: 48px;
	height: 48px;
	line-height: 47px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_btn div.dhxtoolbar_text,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_arw div.arwimg {
	height: 48px;
	line-height: 47px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_text {
	padding-top: 18px;
	padding-bottom: 18px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_sep {
	height: 50px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhxtoolbar_sl_bg_l,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhxtoolbar_sl_bg_m,
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhxtoolbar_sl_bg_r {
	margin-top: 23px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhxtoolbar_sl_pen {
	top: 20px;
}
.dhx_toolbar_dhx_skyblue.dhxtoolbar_icons_48 div.dhx_toolbar_btn .dhxtoolbar_input {
	margin-top: 16px;
	margin-bottom: 16px;
}
div.dhx_toolbar_poly_dhx_skyblue {
	position: absolute;
	border: 1px solid #a4bed4;
	box-shadow: 0 0 3px #ccc;
	padding: 3px 0px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top-right-radius: 2px;
	background-color: #e7f1ff;
	overflow: hidden;
	cursor: default;
	line-height: normal;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
div.dhx_toolbar_poly_dhx_skyblue td {
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	line-height: normal;
	padding: 0px 3px;
	border-top: 1px solid #e7f1ff;
	border-bottom: 1px solid #e7f1ff;
}
div.dhx_toolbar_poly_dhx_skyblue .tr_btn_over td,
div.dhx_toolbar_poly_dhx_skyblue .tr_btn_selected td {
	background-color: #b5deff;
	border-top: 1px solid #a1cef4;
	border-bottom: 1px solid #a1cef4;
}
div.dhx_toolbar_poly_dhx_skyblue .tr_btn_disabled td {
	color: #999999;
}
div.dhx_toolbar_poly_dhx_skyblue td.td_btn_img {
	width: 18px;
	text-align: center;
}
div.dhx_toolbar_poly_dhx_skyblue td.td_btn_img img.btn_sel_img {
	width: 18px;
	height: 18px;
}
div.dhx_toolbar_poly_dhx_skyblue td.td_btn_img i {
	width: 18px;
	height: 18px;
	line-height: 17px;
	text-align: center;
	color: inherit;
	font-size: 1.3em;
}
div.dhx_toolbar_poly_dhx_skyblue td.td_btn_txt div.btn_sel_text {
	padding: 0px 12px 0px 1px;
	height: 22px;
	line-height: 21px;
	overflow: hidden;
}
div.dhx_toolbar_poly_dhx_skyblue tr.tr_sep td {
	padding: 2px 0px;
}
div.dhx_toolbar_poly_dhx_skyblue div.btn_sep {
	position: relative;
	font-size: 1px;
	line-height: 1px;
	height: 0px;
	width: 100%;
	border-top: 1px solid #a4bed5;
}
div.dhx_toolbar_slider_label_dhx_skyblue {
	position: absolute;
	border: 1px solid #a4bed4;
	box-shadow: 0 0 3px #ccc;
	background-color: #e7f1ff;
	padding: 5px 8px;
	border-radius: 2px;
	overflow: hidden;
	cursor: default;
	line-height: normal;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
}
.dhxtoolbar_maxopen_test {
	position: absolute;
	width: 200px;
	height: 50px;
	overflow-x: none;
	overflow-y: auto;
	left: -300px;
	top: 100px;
	border: 1px solid white;
	visibility: hidden;
}
.dhxtoolbar_maxopen_test2 {
	height: 200px;
}
.dhxacc_base_dhx_skyblue div.dhx_cell_acc div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging,
.dhxlayout_base_dhx_skyblue div.dhx_cell_layout div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging,
.dhxtabbar_base_dhx_skyblue div.dhx_cell_tabbar div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging {
	position: relative;
	height: 32px;
	line-height: 31px;
	margin-top: -1px;
	overflow: hidden;
	white-space: nowrap;
}
.dhxtabbar_base_dhx_skyblue div.dhx_cell_tabbar div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging {
	margin-top: 0px;
	height: 31px;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging {
	position: relative;
	height: 32px;
	line-height: 31px;
	margin-top: 3px;
	overflow: hidden;
	white-space: nowrap;
}
.dhxwins_vp_dhx_skyblue div.dhxwin_active div.dhx_cell_wins div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging .dhx_toolbar_dhx_skyblue,
.dhxwins_vp_dhx_skyblue div.dhxwin_inactive div.dhx_cell_wins div.dhx_cell_statusbar_def div.dhx_cell_statusbar_paging .dhx_toolbar_dhx_skyblue {
	border-width: 0px;
	background-image: none;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhx_tooltip {
	display: none;
	position: absolute;
	font-family: Tahoma, Helvetica;
	color: #626262;
	font-size: 11px;
	z-index: 10000;
	padding: 4px 10px 4px 10px;
	border: 1px solid #fff;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-box-shadow: 0 0 3px #d9d9d9;
	-webkit-box-shadow: 0 0 3px #d9d9d9;
	text-shadow: 0px 1px 1px #fff;
	box-shadow: 0 0 5px #d9d9d9;
	background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#efefef),color-stop(0.5,#f6f6f6),color-stop(1,#efefef));
	background: -o-linear-gradient(#efefef 0%,#f6f6f6 50%,#efefef 100%);
	background: -ms-linear-gradient(#efefef 0%,#f6f6f6 50%,#efefef 100%);
	background: -moz-linear-gradient(#efefef 0%,#f6f6f6 50%,#efefef 100%);
	background: #efefef;
}
.dhx_noselect {
	-moz-user-select: none;
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
}
.dhx_allow_selection {
	-moz-user-select: text;
	-user-select: text;
}
.dhx_dataview {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
	-moz-user-select: none;
	-moz-user-select: -moz-none;
	-khtml-select: none;
}
.dhx_drag_zone {
	position: absolute;
	border: 1px solid #A4BED4;
	z-index: 8000;
	-moz-box-shadow: 5px 5px 5px #888;
	-khtml-box-shadow: 5px 5px 5px #888;
}
.dhx_dataview_default_item,
.dhx_dataview_default_item_selected {
	cursor: pointer;
	background-color: #ffffff;
}
.dhx_dataview .dhx_dataview_default_item,
.dhx_dataview .dhx_dataview_default_item_selected {
	border-right: 1px solid #a4bed4;
	border-bottom: 1px dotted #a4bed4;
}
.dhx_dataview_default_item_selected {
	background-color: #a1ceed;
	color: #b5deff;
	border-color: #a1ceed;
	background-repeat: repeat-x;
}
.dhx_dataview_item {
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
}
.dhx_dataview_item .dhx_strong {
	font-weight: bold;
	display: block;
	padding-bottom: 6px;
}
.dhx_dataview_default_item_selected .dhx_light {
	color: #C3C3C3;
}
.dhx_dataview_item .dhx_light {
	color: #919191;
}
.dhx_drag_over {
	background-color: #faf768;
	background-image: url("imgs/dhxdataview_skyblue/marker.png");
	background-repeat: repeat-x;
}
.dhx_pager_item,
.dhx_pager_item_selected {
	float: left;
	text-align: center;
	height: 16px;
	font-family: Tahoma, Helvetica;
	font-size: 10pt;
	border: 1px solid gray;
	padding: 2px 4px;
	margin-left: 5px;
	cursor: pointer;
	background-color: white;
}
.dhx_pager_item_selected {
	background-color: lightgrey;
	color: white;
	cursor: default;
	font-weight: bold;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhx_tooltip {
	display: none;
	position: absolute;
	font-family: Tahoma, Helvetica;
	color: #626262;
	font-size: 11px;
	z-index: 10000;
	padding: 4px 10px 4px 10px;
	border: 1px solid #fff;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-box-shadow: 0 0 3px #d9d9d9;
	-webkit-box-shadow: 0 0 3px #d9d9d9;
	text-shadow: 0px 1px 1px #fff;
	box-shadow: 0 0 5px #d9d9d9;
	background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#efefef),color-stop(0.5,#f6f6f6),color-stop(1,#efefef));
	background: -o-linear-gradient(#efefef 0%,#f6f6f6 50%,#efefef 100%);
	background: -ms-linear-gradient(#efefef 0%,#f6f6f6 50%,#efefef 100%);
	background: -moz-linear-gradient(#efefef 0%,#f6f6f6 50%,#efefef 100%);
	background: #efefef;
}
.dhx_chart {
	position: relative;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	overflow: hidden;
	z-index: 0;
}
.dhx_chart canvas {
	position: absolute;
	left: 0;
	top: 0;
}
.dhx_canvas_text {
	position: absolute;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	z-index: 1000;
}
.dhx_map_img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	border: 0px;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	opacity: 0;
}
.dhx_axis_item_y {
	position: absolute;
	height: 18px;
	line-height: 18px;
	margin-top: -4px;
	text-align: right;
}
.dhx_axis_title_x {
	text-align: center;
}
.dhx_axis_title_y {
	text-align: center;
	font-family: Tahoma, Helvetica;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	height: 18px;
	line-height: 18px;
	font-size: 11px;
}
.dhx_ie_filter {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	zoom: 1;
	font-family: serif;
}
.dhx_chart_legend {
	position: absolute;
	z-index: 1000;
}
.dhx_chart_legend_item {
	height: 18px;
	line-height: 18px;
	padding: 2px;
	cursor: pointer;
}
.dhx_chart_legend_item.hidden {
	color: #aaa;
}
.dhx_axis_item_y.dhx_radar {
	color: #666;
	font-size: 12px;
	padding-right: 3px;
	height: 13px;
	line-height: 13px;
	margin-top: 0px;
}
.dhx_canvas_text.dhx_axis_radar_title {
	margin-top: 0px;
	padding-top: 0px;
}
.dhx_axis_item_y,
.dhx_axis_item_x {
	color: #666;
}
.dhx_axis_item_x {
	padding-top: 2px;
}

/* ECOSYS SC:  this is a v36 style sheet, that happens to work in v412 and makes the tree look how it was before.
The file was copied directly from v36 version of dhtmlxTree.
Sking name is 'eco'
*/
.defaultTreeTable{
			margin : 0px;
			padding : 0px;
			border : 0px;
}
.containerTableStyle { overflow : auto;-webkit-overflow-scrolling: touch; position:relative; top:0; font-size : 12px; -khtml-user-select: none;}
.containerTableStyleRTL span { direction: rtl; unicode-bidi: bidi-override;  }
.containerTableStyleRTL { direction: rtl; overflow : auto; position:relative; top:0; font-size : 12px;}
.standartTreeRow {	font-family : Verdana, Geneva, Arial, Helvetica, sans-serif; 	font-size : 12px; -moz-user-select: none;  }
.selectedTreeRow{ background-color : navy; color:white; font-family : Verdana, Geneva, Arial, Helvetica, sans-serif; 		font-size : 12px;  -moz-user-select: none; }
.dragAndDropRow{ background-color : navy; color:white; }
.standartTreeRow_lor{	text-decoration:underline; background-color : #FFFFF0; font-family : Verdana, Geneva, Arial, Helvetica, sans-serif; 	font-size : 12px; -moz-user-select: none; }
.selectedTreeRow_lor{   text-decoration:underline; background-color : navy; color:white; font-family : Verdana, Geneva, Arial, Helvetica, sans-serif; 		font-size : 12px;  -moz-user-select: none; }

.standartTreeImage{ width:18px; height:18px;  overflow:hidden; border:0; padding:0; margin:0;
font-size:1px; }
.hiddenRow { width:1px;   overflow:hidden;  }
.dragSpanDiv,.dragSpanDiv td{ 	font-size : 12px; 	background-color:white; z-index:999; }
.a_dhx_hidden_input{ position:absolute;  top:-1px; left:-1px; width:1px; height:1px; border:none; background:none; }
.a_dhx_hidden_input{ position:absolute;  top:-1px; left:-1px; width:1px; height:1px; border:none; background:none; }

.selectionBox{
background-color: #FFFFCC;
}
.selectionBar {
	top:0;
	background-color: Black;
	position:absolute;
	overflow:hidden;
	height: 2px;
	z-index : 11;
}

.intreeeditRow{
  font-size:8pt; height:16px; border:1px solid silver; padding:0; margin:0;
  margin-left:4px;
  -moz-user-select:  text;   
  -khtml-user-select: text;
}
.dhx_tree_textSign{
   font-size:8pt;
   font-family:monospace;
   width:21px;
   color:black;
   padding:0px;
   margin:0px;
   cursor:pointer;
   text-align: center;
}
.dhx_tree_opacity{
    opacity:0;
    -moz-opacity:0;
    filter:alpha(opacity=0);
}
.dhx_bg_img_fix{
width:18px;
height:18px;
background-repeat: no-repeat;
background-position: center;
background-position-x: center;
background-position-y: center;
}

.dhxtree_dhx_black, .dhxtree_dhx_skyblue{
	background:white;
	color:black;
}
*html .dhxtree_dhx_skyblue .standartTreeRow, *html .dhxtree_dhx_skyblue .standartTreeRow_lor{
	border-right:0px solid red;
	border-left:0px solid red;
}
*html .dhxtree_dhx_skyblue span.standartTreeRow, *html .dhxtree_dhx_skyblue span.standartTreeRow_lor{
	margin-left:1px;	
}

.dhxtree_dhx_skyblue .standartTreeRow, .dhxtree_dhx_skyblue .standartTreeRow_lor{
	border-right:1px solid transparent;
	border-left: 1px solid transparent;
	font-family:Tahoma;
	font-size:11px !important;
	overflow:hidden;
	padding:0px 0px 0px 0px;
}
.dhxtree_dhx_skyblue .selectedTreeRow_lor, .dhxtree_dhx_skyblue .selectedTreeRow{
	background-color:white;
	background-image:url('imgs/dhxtree_eco/sky_blue_sel_tree.png');
	background-repeat:repeat-x;
	border:1px solid #FFB951;
	color:black;

	line-height:17px;
	
	font-size:11px !important;
	font-family:Tahoma;
	overflow:hidden;
}
html > body /**/ .dhxtree_dhx_skyblue .selectedTreeRow, html > body /**/ .dhxtree_dhx_skyblue .selectedTreeRow_lor{
	padding:1px 0px 1px 0px;
	line-height:normal;
	display:inline-block;
	height:13px;
}
body:nth-of-type(1) .dhxtree_dhx_skyblue span.selectedTreeRow, body:nth-of-type(1) .dhxtree_dhx_skyblue span.selectedTreeRow_lor{
	padding:1px 0px 1px 0px;
  	display:inline-block;
  	padding-top:0px;
  	height:13px;
}
body:nth-of-type(1) .dhxtree_dhx_skyblue span.standartTreeRow, body:nth-of-type(1) .dhxtree_dhx_skyblue span.standartTreeRow_lor{
  	display:inline-block;
  	height:14px;
}

.dhxtree_dhx_web .selectedTreeRow_lor, .dhxtree_dhx_web .selectedTreeRow{
	background-color:transparent;
	
}
.dhxtree_dhx_web span.selectedTreeRow_lor , .dhxtree_dhx_web span.selectedTreeRow{
	background-color:#ACDAF0;
	color:black;
}

.dhxtree_dhx_web td.standartTreeRow, .dhxtree_dhx_web td.selectedTreeRow{
	padding-left:2px;
}
.dhxtree_dhx_web span.standartTreeRow, .dhxtree_dhx_web span.selectedTreeRow{
	padding-left:3px !important;
}



.dhxtree_dhx_web .standartTreeRow, .dhxtree_dhx_web .standartTreeRow, .dhxtree_dhx_web .selectedTreeRow_lor, .dhxtree_dhx_web .selectedTreeRow{
	font-size:12px;
	font-family:Tahoma;
	overflow:hidden;
}


.dhxtree_dhx_terrace .standartTreeRow, .dhxtree_dhx_terrace .standartTreeRow, .dhxtree_dhx_terrace .selectedTreeRow_lor, .dhxtree_dhx_terrace .selectedTreeRow{
	font-family:Arial;
	font-size:13px;
	padding:3px 1px 4px 2px;
}

.dhxtree_dhx_terrace .standartTreeImage{ 
	padding-left:3px 
}
.dhxtree_dhx_terrace .selectedTreeRow_lor, .dhxtree_dhx_terrace .selectedTreeRow{
	background-color:transparent;
	
}
.dhxtree_dhx_terrace span.selectedTreeRow_lor , .dhxtree_dhx_terrace span.selectedTreeRow{
	background-color:#fff3a1;
	color:black;
}
/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhxtreeview_dhx_skyblue {
	position: relative;
	overflow: hidden;
	background-color: white;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont {
	position: absolute;
	overflow: auto;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area {
	position: relative;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_kids_cont {
	position: relative;
	-webkit-transition: opacity 0.1s;
	-moz-transition: opacity 0.1s;
	-ms-transition: opacity 0.1s;
	-o-transition: opacity 0.1s;
	transition: opacity 0.1s;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item {
	position: relative;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	white-space: nowrap;
	cursor: default;
	margin-top: 1px;
	margin-bottom: 1px;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text {
	position: relative;
	padding: 0px;
	margin: 0px;
	height: 24px;
	line-height: 23px;
	border: 1px solid #ffffff;
	color: inherit;
	vertical-align: middle;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: default;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_label {
	position: absolute;
	top: 0px;
	padding: 0px 10px 0px 4px;
	color: black;
	background-color: #ffffff;
	height: 24px;
	line-height: inherit;
	font: inherit;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected:hover {
	background-color: #b5deff;
	border-color: #a4bed4;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected div.dhxtreeview_item_label,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected:hover div.dhxtreeview_item_label {
	background-color: #b5deff;
	border-color: #a4bed4;
	color: black;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text:hover {
	background-color: #f1f7ff;
	border-color: #b9cdde;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text:hover div.dhxtreeview_item_label {
	border-color: #b9cdde;
	background-color: #f1f7ff;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon {
	position: absolute;
	width: 20px;
	top: 0px;
	height: 24px;
	line-height: 22px;
	text-align: center;
	font-size: 14px;
	color: #333333;
	overflow: hidden;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 20px;
	height: 24px;
	background-repeat: no-repeat;
	background-position: center center;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_plus {
	background-image: url(imgs/dhxtreeview_skyblue/icon_plus.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_minus {
	background-image: url(imgs/dhxtreeview_skyblue/icon_minus.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_file {
	background-image: url(imgs/dhxtreeview_skyblue/icon_file.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_folder_closed {
	background-image: url(imgs/dhxtreeview_skyblue/icon_folder_closed.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_folder_opened {
	background-image: url(imgs/dhxtreeview_skyblue/icon_folder_opened.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_loading {
	background-image: url(imgs/dhxtreeview_skyblue/loading.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_chbx_0 {
	background-image: url(imgs/dhxtreeview_skyblue/icon_chbx_0.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_chbx_1 {
	background-image: url(imgs/dhxtreeview_skyblue/icon_chbx_1.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_chbx_dis_0 {
	background-image: url(imgs/dhxtreeview_skyblue/icon_chbx_dis_0.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.dhxtreeview_icon.dhxtreeview_icon_chbx_dis_1 {
	background-image: url(imgs/dhxtreeview_skyblue/icon_chbx_dis_1.gif);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.fa.fa-folder-open-o,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.fa.fa-folder-o {
	position: absolute;
	line-height: 24px;
	left: 4px;
	width: 10px;
	text-align: left;
	overflow: visible;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.fa.fa-square-o,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.fa.fa-check-square-o {
	position: absolute;
	line-height: 24px;
	left: 4px;
	width: 10px;
	text-align: left;
	overflow: visible;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.fa.fa-square-o.dhx-disabled,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text div.dhxtreeview_item_icon i.fa.fa-check-square-o.dhx-disabled {
	color: #a6a6a6;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_drop_preview {
	display: none;
	position: absolute;
	height: 0px;
	width: 40px;
	border-bottom: 2px dotted #008dbc;
	z-index: 1;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_drop_preview.dhxtreeview_drop_0 {
	display: block;
	top: -2px;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_drop_preview.dhxtreeview_drop_2 {
	display: block;
	top: 25px;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item.dhxtreeview_item_dragged {
	opacity: 0.3;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_drop_1,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_drop_1:hover {
	background-color: #fff0da;
	border-color: #d1c5b5;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_drop_1 div.dhxtreeview_item_label,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_drop_1:hover div.dhxtreeview_item_label {
	background-color: #fff0da;
	border-color: #d1c5b5;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected:hover {
	background-color: #fff0da;
	border-color: #d1c5b5;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected div.dhxtreeview_item_label,
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text.dhxtreeview_item_text_selected:hover div.dhxtreeview_item_label {
	background-color: #fff0da;
	border-color: #d1c5b5;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text:hover {
	background-color: #ffffff;
	border-color: #ffffff;
}
.dhxtreeview_dhx_skyblue div.dhxtreeview_cont.dhxtreeview_dnd_mode div.dhxtreeview_area div.dhxtreeview_item div.dhxtreeview_item_text:hover div.dhxtreeview_item_label {
	background-color: #ffffff;
	border-color: #ffffff;
}
.dhxtreeview_dhx_skyblue.dhxtreeview_with_border {
	border: 1px solid #a4bed4;
}
.dhxtreeview_dhx_skyblue.dhxtreeview_icon_width {
	position: absolute;
	left: 0px;
	top: -100px;
	width: 20px;
	height: 1px;
	font-size: 1px;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
}
.dhxtreeview_dragged_obj_dhx_skyblue {
	position: absolute;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	white-space: nowrap;
	cursor: default;
	background-color: #fcfcfc;
	border-radius: 1px;
	box-shadow: 0px 0px 10px rgba(90,90,90,0.2);
	padding: 5px 14px;
	border: 1px solid #cccccc;
	opacity: 0.9;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
}
html.dhxtreeview_dnd_mode,
html.dhxtreeview_dnd_mode *,
body.dhxtreeview_dnd_mode,
body.dhxtreeview_dnd_mode * {
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area td.dhx_popup_td div.dhxtreeview_dhx_skyblue div.dhxtreeview_item div.dhxtreeview_item_label {
	white-space: nowrap;
}
.dhxtreeview_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}

/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

.dhxmenu_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}
.dhtmlxMenu_dhx_skyblue_Middle {
	position: relative;
	height: 30px;
	border: none;
	overflow: hidden;
	background-color: #ebebeb;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhtmlxMenu_dhx_skyblue_Middle div.top_sep {
	float: left;
	position: relative;
	height: 22px;
	width: 0px;
	border-left: 1px solid #dddddd;
	margin: 4px 2px 0px 0px;
	line-height: 1px;
	font-size: 1px;
	overflow: hidden;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhtmlxMenu_dhx_skyblue_Middle img.dhtmlxMenu_TopLevel_Item_Icon {
	float: left;
	margin: 2px 3px 0px 3px;
	width: 18px;
	height: 18px;
}
.dhtmlxMenu_dhx_skyblue_Middle div.dhtmlxMenu_TopLevel_Text_right {
	position: absolute;
	top: 8px;
	left: none;
	right: 8px;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	cursor: default;
}
.dhtmlxMenu_dhx_skyblue_Middle div.dhtmlxMenu_TopLevel_Text_left {
	position: absolute;
	top: 8px;
	right: none;
	left: 8px;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	cursor: default;
}
.dhtmlxMenu_dhx_skyblue_Middle.dir_left div.align_left {
	float: left;
}
.dhtmlxMenu_dhx_skyblue_Middle.dir_left div.align_right {
	float: right;
}
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Normal,
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Disabled,
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Selected {
	position: relative;
	float: left;
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	cursor: default;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	height: 22px;
	line-height: 22px;
	vertical-align: middle;
	margin: 3px 2px 0px 0px;
	padding: 0px 3px;
}
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Normal div.top_level_text,
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Disabled div.top_level_text,
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Selected div.top_level_text {
	float: left;
	margin: 0px 3px;
}
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Normal i,
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Disabled i,
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Selected i {
	height: inherit;
	line-height: inherit;
	float: left;
	color: inherit;
	margin: 0px 4px;
	font-size: 1.2em;
}
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Normal,
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Disabled {
	border: 1px solid #ebebeb;
}
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Disabled {
	color: #999999 !important;
}
div.dhtmlxMenu_dhx_skyblue_TopLevel_Item_Selected {
	border: 1px solid #a1ceed;
	background-color: #b5deff;
	color: black;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon {
	position: absolute;
	border: 1px solid #a4bed4;
	box-shadow: 0 0 3px #ccc;
	padding: 3px 0px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top-right-radius: 2px;
	background-color: #e7f1ff;
	overflow: hidden;
	cursor: default;
	line-height: normal;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td {
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	line-height: normal;
	padding: 0px 3px;
	border-top: 1px solid #e7f1ff;
	border-bottom: 1px solid #e7f1ff;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected td {
	background-color: #b5deff;
	border-top: 1px solid #a1ceed;
	border-bottom: 1px solid #a1ceed;
	color: black;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected div.sub_item_hk {
	color: #333333 !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon {
	width: 18px;
	text-align: center;
	vertical-align: middle;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon img.sub_icon {
	float: left;
	margin: 0px;
	width: 18px;
	height: 18px;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon i {
	float: left;
	text-align: center;
	width: 18px;
	height: 18px;
	line-height: 17px;
	font-size: 1.3em;
	color: inherit;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis td.sub_item_icon i {
	color: #999999 !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_item_text {
	padding: 0px 12px 0px 1px;
	height: 22px;
	line-height: 21px;
	white-space: nowrap;
	text-align: left;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_item_text {
	color: #999999 !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk {
	padding-left: 8px;
	padding-right: 8px;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk div.sub_item_hk {
	font-family: Tahoma, Helvetica;
	font-size: 10px;
	color: #4d4d4d;
	text-align: right;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis td.sub_item_hk div.sub_item_hk {
	color: #9b9b9b !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow {
	width: 4px;
	margin: 0px 2px 0px 5px;
	height: 22px;
	background-image: url("imgs/dhxmenu_skyblue/dhxmenu_subar.gif");
	background-repeat: no-repeat;
	background-position: 0px 0px;
	overflow: hidden;
	font-size: 1px;
	float: right;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected div.complex_arrow {
	background-position: -4px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.complex_arrow {
	background-position: -8px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow_loading {
	width: 11px;
	height: 22px;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url("imgs/dhxmenu_skyblue/dhxmenu_loader.gif");
	float: right;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon {
	float: left;
	margin: 0px;
	width: 18px;
	height: 18px;
	background-position: top right;
	background-repeat: no-repeat;
	background-image: url("imgs/dhxmenu_skyblue/dhxmenu_chrd.gif");
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_0 {
	background-position: 0px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_1 {
	background-position: -18px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_0 {
	background-position: -36px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_1 {
	background-position: -54px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_0 {
	background-position: -72px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_1 {
	background-position: -90px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_0 {
	background-position: -108px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_1 {
	background-position: -126px 0px !important;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon tr.sub_sep td {
	padding: 2px 0px;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon div.sub_sep {
	position: static;
	font-size: 1px;
	line-height: 1px;
	height: 1px;
	width: 100%;
	border-top: 1px solid #a4bed4;
}
iframe.dhtmlxMenu_IE6CoverFix_dhx_skyblue {
	position: absolute;
	border: none;
	background: #000000;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowUp,
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowUp_Over,
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowUp_Disabled {
	position: relative;
	font-size: 1px;
	border-bottom: 1px solid #a4bed4;
	background-image: url("imgs/dhxmenu_skyblue/dhxmenu_arrow_up.gif");
	background-repeat: no-repeat;
	background-position: center center;
	padding: 8px 0px;
	margin-bottom: 3px;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowUp_Disabled {
	background-image: url("imgs/dhxmenu_skyblue/dhxmenu_arrow_up_dis.gif");
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowDown,
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowDown_Over,
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowDown_Disabled {
	position: relative;
	font-size: 1px;
	border-top: 1px solid #a4bed4;
	background-image: url("imgs/dhxmenu_skyblue/dhxmenu_arrow_down.gif");
	background-repeat: no-repeat;
	background-position: center center;
	padding: 8px 0px;
	margin-top: 3px;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_ArrowDown_Disabled {
	background-image: url("imgs/dhxmenu_skyblue/dhxmenu_arrow_down_dis.gif");
}

/*

ECOSYS Custom skin based on dhtmlxmenu_dhx_skyblue.css

*/

.dhxmenu_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}
.dhtmlxMenu_eco_black_Middle {
	position: relative;
	height: 22px;
	border: none;
	overflow: hidden;
	background-image: url("imgs/dhxmenu_black/dhtmlxmenu_bg.gif");
	background-position: top;
	background-repeat: repeat-x;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhtmlxMenu_eco_black_Middle div.top_sep {
	float: left;
	position: relative;
	height: 22px;
	width: 0px;
	border-left: 1px solid #dddddd;
	margin: 4px 2px 0px 0px;
	line-height: 1px;
	font-size: 1px;
	overflow: hidden;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.dhtmlxMenu_eco_black_Middle img.dhtmlxMenu_TopLevel_Item_Icon {
	float: left;
	margin: 2px 3px 0px 3px;
	width: 16px;
	height: 16px;
}
.dhtmlxMenu_eco_black_Middle div.dhtmlxMenu_TopLevel_Text_right {
	position: absolute;
	top: 8px;
	left: none;
	right: 8px;
	font-family:  Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: black;
	cursor: default;
}
.dhtmlxMenu_eco_black_Middle div.dhtmlxMenu_TopLevel_Text_left {
	position: absolute;
	top: 8px;
	right: none;
	left: 8px;
	font-family:  Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: black;
	cursor: default;
}
.dhtmlxMenu_eco_black_Middle.dir_left div.align_left {
	float: left;
}
.dhtmlxMenu_eco_black_Middle.dir_left div.align_right {
	float: right;
}
div.dhtmlxMenu_eco_black_TopLevel_Item_Normal,
div.dhtmlxMenu_eco_black_TopLevel_Item_Disabled,
div.dhtmlxMenu_eco_black_TopLevel_Item_Selected {
	position: relative;
	float: left;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: white;
	cursor: default;
	white-space: nowrap;
    -webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	letter-spacing: .02em;
	font-weight: bold;
	height: 17px;
	line-height: 16px;
	vertical-align: middle;
	margin: 2px 2px 0 0;
	padding: 1px 4px;
}
div.dhtmlxMenu_eco_black_TopLevel_Item_Normal div.top_level_text,
div.dhtmlxMenu_eco_black_TopLevel_Item_Disabled div.top_level_text,
div.dhtmlxMenu_eco_black_TopLevel_Item_Selected div.top_level_text {
	float: left;
	margin: 0px 3px;
}
div.dhtmlxMenu_eco_black_TopLevel_Item_Normal,
div.dhtmlxMenu_eco_black_TopLevel_Item_Disabled {
	/* padding: 1px; */
}
div.dhtmlxMenu_eco_black_TopLevel_Item_Disabled {
	color: #999999 !important;
}
div.dhtmlxMenu_eco_black_TopLevel_Item_Selected {
	border: 1px solid #000;/*  #a1ceed; */
	border-right-color: #999;
	border-bottom-color: #999;
	background-color: #333;/*  #b5deff; */
	color: #eee;
	margin: 2px 2px 2px 0;
	padding: 0 3px;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon {
	position: absolute;
	border: 1px solid #000;
	box-shadow: 0 0 3px #ccc;
	padding: 3px 0px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top-right-radius: 2px;
	background-color: #333;/* #e7f1ff; */
	overflow: hidden;
	cursor: default;
	line-height: normal;
	color: #eee;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td {
	font-family:  Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	color: #eee;/* black; */
	line-height: normal;
	padding: 0px 3px;
	border-top: 1px solid #333;/* #e7f1ff; */
	border-bottom: 1px solid #333;/* #e7f1ff; */
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected td {
	background-color: #000;/*  #b5deff; */
	border-top: 1px solid #333;/*  #a1ceed; */
	border-bottom: 1px solid #333;/*  #a1ceed; */
	color: #eee;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected div.sub_item_hk {
	color: #333333 !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon {
	width: 18px;
	text-align: center;
	vertical-align: middle;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon img.sub_icon {
	float: left;
	margin: 0px;
	width: 16px;
	height: 16px;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_item_text {
	padding: 2px 12px 0px 1px;
	height: 22px;
	line-height: 21px;
	white-space: nowrap;
	text-align: left;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_item_text {
	color: #999999 !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk {
	padding-left: 8px;
	padding-right: 8px;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk div.sub_item_hk {
	font-family:  Arial, Helvetica, sans-serif;
	font-size: 10px;
	color: #4d4d4d;
	text-align: right;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis td.sub_item_hk div.sub_item_hk {
	color: #9b9b9b !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow {
	width: 4px;
	margin: 0 2px 0px 5px;
	height: 22px;
	background-image: url("imgs/dhxmenu_black/dhxmenu_subar.gif");
	background-repeat: no-repeat;
	background-position: -8px 0;
	overflow: hidden;
	font-size: 1px;
	float: right;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected div.complex_arrow {
	background-position: -8px 0 !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.complex_arrow {
	background-position: 0 0 !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow_loading {
	width: 11px;
	height: 22px;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url("imgs/dhxmenu_black/dhxmenu_loader.gif");
	float: right;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon {
	float: left;
	margin: 0px;
	width: 18px;
	height: 18px;
	background-position: top right;
	background-repeat: no-repeat;
	background-image: url("imgs/dhxmenu_black/dhxmenu_chrd.gif");
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_0 {
	background-position: 0px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_1 {
	background-position: -18px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_0 {
	background-position: -36px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_1 {
	background-position: -54px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_0 {
	background-position: -72px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_1 {
	background-position: -90px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_0 {
	background-position: -108px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_1 {
	background-position: -126px 0px !important;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon tr.sub_sep td {
	padding: 2px 3px;
}
div.dhtmlxMenu_eco_black_SubLevelArea_Polygon div.sub_sep {
	position: relative;
	font-size: 1px;
	line-height: 1px;
	height: 0px;
	width: 100%;
	border-top: 1px solid #999;/* #e3e3e3; */ /* #a4bed4; */
}
iframe.dhtmlxMenu_IE6CoverFix_eco_black {
	position: absolute;
	border: none;
	background: #000000;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowUp,
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowUp_Over,
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowUp_Disabled {
	position: relative;
	font-size: 1px;
	border-bottom: 1px solid #FFFFFF;
    background-image: url("imgs/dhxmenu_black/dhxmenu_arrow_up_dis.gif");
	background-repeat: no-repeat;
	background-position: center center;
	padding: 8px 0px;
	margin-bottom: 3px;
}
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowUp_Disabled {
    background-image: url("imgs/dhxmenu_black/dhxmenu_arrow_up.gif");
}
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowDown,
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowDown_Over,
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowDown_Disabled {
	position: relative;
	font-size: 1px;
	border-top: 1px solid #FFFFFF;
    background-image: url("imgs/dhxmenu_black/dhxmenu_arrow_down_dis.gif");
	background-repeat: no-repeat;
	background-position: center center;
	padding: 8px 0px;
	margin-top: 3px;
}
div.dhtmlxMenu_eco_black_SubLevelArea_ArrowDown_Disabled {
	background-image: url("imgs/dhxmenu_black/dhxmenu_arrow_down.gif");
}

/*
								"ECOSYS DROPDOWN" DHTMLXMENU SKIN 2014 
								Based on eco_dropdown
*/
/****************************************************************************************************************************************************/
.dhxmenu_skin_detect {
    position: absolute;
    left: 0px;
    top: -100px;
    margin: 0;
    padding: 0;
    border: 0px solid white;
    width: 10px;
    height: 10px;
    overflow: hidden;
}

/*									TOPLEVEL								    */
.dhtmlxMenu_eco_dropdown_Middle {
    position: relative;
    height: 15px;
    border: none;
    overflow: hidden;
    background-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

/* toplevel separator */
.dhtmlxMenu_eco_dropdown_Middle div.top_sep {
    float: left;
    position: relative;
    height: 14px;
    width: 0px;
    border-left: 1px solid #dddddd;
    margin: 4px 2px 0px 0px;
    line-height: 1px;
    font-size: 1px;
    overflow: hidden;
    cursor: default;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

}

/* top level image */
.dhtmlxMenu_eco_dropdown_Middle img.dhtmlxMenu_TopLevel_Item_Icon {
    float: right;
    margin: 5px 2px 0px 2px;
    width: 9px;
    height: 5px;
}
/*	TOPLEVEL TEXT    */
.dhtmlxMenu_eco_dropdown_Middle div.dhtmlxMenu_TopLevel_Text_right {
    position: absolute;
    top: 5px;
    left: none;
    right: 8px;
    font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
    font-size: 11px;
    color: #000000;
    cursor: default;
}
.dhtmlxMenu_eco_dropdown_Middle div.dhtmlxMenu_TopLevel_Text_left {
    position: absolute;
    top: 5px;
    right: none;
    left: 8px;
    font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
    font-size: 11px;
    color: #000000;
    cursor: default;
}
.dhtmlxMenu_eco_dropdown_Middle.dir_left div.align_left {
	float: left;
}
.dhtmlxMenu_eco_dropdown_Middle.dir_left div.align_right {
	float: right;
}


/****************************************************************************************************************************************************/
/*									TOPLEVEL ITEMS								    */
/* toplevel item normal */
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected {
	position: relative;
	float: left;
	font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
	font-size: 11px;
	font-weight: normal;
	cursor: default;
	white-space: nowrap;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
	height: 15px;
	line-height: 15px;
	vertical-align: middle;
	margin-top: 1px;
	margin-left: 0px;
	margin-right: 2px;
	padding-left: 4px;
	padding-right: 4px;
	border-left: 0;
	border-right: 0;
	color: #000000;
	
}
/* top level text*/
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal div.top_level_text,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled div.top_level_text,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected div.top_level_text {
    float: left;
    margin-left: 2px;
    margin-right: 2px;
}
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal i,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled i,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected i {
    height: inherit;
    line-height: inherit;
    float: left;
    color: inherit;
    margin: 0px 4px;
    font-size: 1em;
}
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Normal,
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled {
    border: none;
}
/* toplevel item disabled */
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Disabled {
	color: #999999 !important;
}
/* toplevel item selected (over) */
div.dhtmlxMenu_eco_dropdown_TopLevel_Item_Selected {
    border: 1px solid #a1ceed;
    background-color: #b5deff;
    color: black;
}



/****************************************************************************************************************************************************/
/*									SUBLEVEL POLYGON							    */
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon {
	position: absolute;
	border: #a4bed4 1px solid;
    box-shadow: 0 0 3px #ccc;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #fff;
    overflow: hidden;
	cursor: default;
    line-height: normal;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;


}

/****************************************************************************************************************************************************/
/*									SUBLEVEL ITEMS								    */

div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td{
    font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
    font-size: 11px;
    color: black;
    line-height: normal;
    padding: 0px 3px;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected td {
    background-color: #b5deff;
    border-top: 1px solid #a1ceed;
    border-bottom: 1px solid #a1ceed;
    color: black;
}
div.dhtmlxMenu_dhx_skyblue_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected div.sub_item_hk {
    color: #333333 !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon {
	width: 16px;
	text-align: center;
    vertical-align: middle;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon img.sub_icon {
	width: 16px;
	height: 16px;
    float: left;
	margin: 0px;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon i {
    float: left;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 1.3em;
    color: inherit;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis td.sub_item_icon i {
    color: #999999 !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_item_text {
    padding: 0px 12px 0px 1px;
    height: 20px;
    line-height: 19px;
    white-space: nowrap;
    text-align: left;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_item_text {
	color: #999999 !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk {
	padding-left: 8px;
	padding-right: 8px;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk div.sub_item_hk {
	font-family: Tahoma, Geneva, "Segoe UI", Segoe, sans-serif;
	font-size: 10px;
	color: #000;
	text-align: right;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis td.sub_item_hk div.sub_item_hk {
	color: #cecece !important;
}



div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon tr.sub_sep {
	height: 3px;
	font-size: 1px;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon div.sub_sep {
	font-size: 1px;
	background-image: url("imgs/dhxmenu_dropdown/dhxmenu_subsepbg.gif");
	background-repeat: repeat-x;
	background-position: top;
	cursor: default;
	height: 3px;
}
/****************************************************************************************************************************************************/
/*									SUBLEVEL ARROWS								    */

div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow {
    width: 4px;
    margin: 0px 2px 0px 5px;
    height: 20px;
    background-image: url("imgs/dhxmenu_dropdown/dhxmenu_subar.gif");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    overflow: hidden;
    font-size: 1px;
    float: right;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected div.complex_arrow {
	background-position: -4px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.complex_arrow {
	background-position: -8px 0px !important;
}
/****************************************************************************************************************************************************/
/*									LOADING ICON								    */
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow_loading {
	width: 11px;
	height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
	background-image: url("imgs/dhxmenu_dropdown/dhxmenu_loader.gif");
    float: right;
}
/****************************************************************************************************************************************************/
/*									ITEM'S CHECKS, RADIOS							    */
/* sublevel */
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon {
    float: left;
    margin: 0px 2px 0px 2px;
    width: 18px;
    height: 18px;
    background-position: top right;
    background-repeat: no-repeat;
    background-image: url("imgs/dhxmenu_dropdown/dhxmenu_chrd.gif");
}
/* checkboxes */
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_0 {
    background-position: 0px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_1 {
    background-position: -18px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_0 {
    background-position: -36px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_1 {
    background-position: -54px 0px !important;
}
/* radios */
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_0 {
    background-position: -72px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_1 {
    background-position: -90px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_0 {
    background-position: -108px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_1 {
    background-position: -126px 0px !important;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon tr.sub_sep td {
    padding: 2px 3px;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_Polygon div.sub_sep {
    position: relative;
    font-size: 1px;
    line-height: 1px;
    height: 0px;
    width: 100%;
    border-top: 1px solid #a4bed4;
}
iframe.dhtmlxMenu_IE6CoverFix_dhx_skyblue {
    position: absolute;
    border: none;
    background: #000000;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
}
/****************************************************************************************************************************************************/
/*									UP/DOWN OVERFLOW ARROWS							    */
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowUp,
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowUp_Over,
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowUp_Disabled {
    position: relative;
    font-size: 1px;
    border-bottom: 1px solid #a4bed4;
    background-image: url("imgs/dhxmenu_dropdown/dhxmenu_arrow_up.gif");
    background-repeat: no-repeat;
    background-position: center center;
    padding: 8px 0px;
    margin-bottom: 3px;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowUp_Disabled {
    background-image: url("imgs/dhxmenu_dropdown/dhxmenu_arrow_up_dis.gif");
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowDown,
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowDown_Over,
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowDown_Disabled {
    position: relative;
    font-size: 1px;
    border-top: 1px solid #a4bed4;
    background-image: url("imgs/dhxmenu_dropdown/dhxmenu_arrow_down.gif");
    background-repeat: no-repeat;
    background-position: center center;
    padding: 8px 0px;
    margin-top: 3px;
}
div.dhtmlxMenu_eco_dropdown_SubLevelArea_ArrowDown_Disabled {
    background-image: url("imgs/dhxmenu_dropdown/dhxmenu_arrow_down_dis.gif");
}

/****************************************************************************************************************************************************/


/*
Product Name: dhtmlxSuite 
Version: 5.1.0 
Edition: Professional 
License: content of this file is covered by DHTMLX Commercial or Enterprise license. Usage without proper license is prohibited. To obtain it contact sales@dhtmlx.com
Copyright UAB Dinamenta http://www.dhtmlx.com
*/

/*
	skin detected: dhx_skyblue
	include extra file: skins/dhx_skyblue.less
*/

div.dhx_popup_dhx_skyblue {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	position: absolute;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area {
	position: relative;
	margin: 10px;
	padding: 3px 0px;
	border: 1px solid #a4bed4;
	box-shadow: 0 0 3px #ccc;
	background-color: #e7f1ff;
	border-radius: 2px;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area td.dhx_popup_td {
	font-family: Tahoma, Helvetica;
	font-size: 11px;
	color: black;
	text-align: left;
	vertical-align: middle;
	padding: 0px 8px;
	height: 24px;
	line-height: 23px;
	border-top: 1px solid #e7f1ff;
	border-bottom: 1px solid #e7f1ff;
	white-space: nowrap;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area td.dhx_popup_td div.dhtmlxcalendar_dhx_skyblue {
	position: relative;
	float: left;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area td.dhx_popup_td * {
	white-space: normal;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhx_popup_sep td.dhx_popup_sep {
	font-size: 1px;
	padding: 2px 0px;
	position: relative;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhx_popup_sep td.dhx_popup_sep div.dhx_popup_sep {
	position: relative;
	font-size: 1px;
	line-height: 1px;
	height: 0px;
	width: 100%;
	border-top: 1px solid #a4bed5;
	overflow: hidden;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area td.dhx_popup_td:first-child {
	padding-left: 16px;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area td.dhx_popup_td:last-child {
	padding-right: 16px;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr:hover td.dhx_popup_td {
	background-color: #b5d8ff;
	border-top: 1px solid #8dcef4;
	border-bottom: 1px solid #8dcef4;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode:hover td.dhx_popup_td {
	background: none;
	border-top: 1px solid #e7f1ff;
	border-bottom: 1px solid #e7f1ff;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	height: auto;
	line-height: inherit;
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	-o-user-select: text;
	user-select: text;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td .dhtmlxcalendar_container.dhtmlxcalendar_skin_dhx_skyblue {
	position: relative !important;
	background-image: none;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td div.dhxtree_dhx_skyblue {
	background: none;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td div.dhxeditor_dhx_skyblue {
	border: 1px solid #a4bed4;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td .dhxform_obj_dhx_skyblue div.dhxeditor_dhx_skyblue {
	border-width: 0px;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td div.dhtmlxcp_dhx_skyblue div.dhxcp_g_area {
	position: relative;
	background-color: #e7f1ff;
	border: 0px solid white;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td div.dhtmlxcp_dhx_skyblue div.dhxcp_g_area div.dhxcp_sub_area {
	background: #e7f1ff;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area tr.dhxnode td.dhx_popup_td div.dhxcarousel_cont {
	background-color: #e7f1ff;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area.dhx_popup_area_ie {
	box-shadow: 0 0 6px #ccc;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area.dhx_popup_area_ie tr.dhxnode td.dhx_popup_td {
	height: 1em;
	line-height: 1em;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area.dhx_popup_area_ie td.dhx_popup_td.dhx_popup_td_single {
	padding-left: 16px;
	padding-right: 16px;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area.dhx_popup_area_ie td.dhx_popup_td.dhx_popup_td_first {
	padding-left: 16px;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area.dhx_popup_area_ie td.dhx_popup_td.dhx_popup_td_last {
	padding-right: 16px;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_area.dhx_popup_area_ie tr.tr_hover td.dhx_popup_td {
	background-color: #b5d8ff;
	border-top: 1px solid #8dcef4;
	border-bottom: 1px solid #8dcef4;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_arrow {
	position: absolute;
	background-repeat: no-repeat;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_arrow.dhx_popup_arrow_bottom {
	width: 19px;
	height: 16px;
	background-image: url("imgs/dhxpopup_skyblue/dhxpopup_arrow_bottom.gif");
	background-position: top center;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_arrow.dhx_popup_arrow_top {
	width: 19px;
	height: 16px;
	background-image: url("imgs/dhxpopup_skyblue/dhxpopup_arrow_top.gif");
	background-position: bottom center;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_arrow.dhx_popup_arrow_right {
	width: 16px;
	height: 19px;
	background-image: url("imgs/dhxpopup_skyblue/dhxpopup_arrow_right.gif");
	background-position: center left;
}
div.dhx_popup_dhx_skyblue div.dhx_popup_arrow.dhx_popup_arrow_left {
	width: 16px;
	height: 19px;
	background-image: url("imgs/dhxpopup_skyblue/dhxpopup_arrow_left.gif");
	background-position: center right;
}
div.dhx_popup_skin_detect {
	position: absolute;
	left: 0px;
	top: -100px;
	margin: 0;
	padding: 0;
	border: 0px solid white;
	width: 10px;
	height: 10px;
	overflow: hidden;
}
