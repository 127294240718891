/* noto-sans-regular - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/noto-sans-v6-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans'), local('NotoSans'),
    url('./fonts/noto-sans-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    /*url('./fonts/noto-sans-v6-latin-regular.woff2') format('woff2'), !* Super Modern Browsers *!*/
    url('./fonts/noto-sans-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./fonts/noto-sans-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/noto-sans-v6-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/noto-sans-v6-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans Bold'), local('NotoSans-Bold'),
    url('./fonts/noto-sans-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    /*url('./fonts/noto-sans-v6-latin-700.woff2') format('woff2'), !* Super Modern Browsers *!*/
    url('./fonts/noto-sans-v6-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./fonts/noto-sans-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/noto-sans-v6-latin-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}